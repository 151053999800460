import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as CryptoJS  from 'crypto-js';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  //Variables para el registro o Inicio de secsión
  ListaDepartamentos = [];
  ListaCiudades = [];
  UsuarioRegistro:string = "";
  ClaveRegistro:string = "";
  ClaveComfirma:string = "";
  NombresRegistro:string = "";
  ApellidosRegistro:string = "";
  TipoDocumentoRegistro:string ="-1";
  IdentificacionRegistro:number;
  DepartamentoRegistro:string ="-1";
  CiudadRegistro:string = "-1";
  TelefonoRegistro:number;
  CelularRegistro:number;
  DireccionRegistro:string = "";
  EmailRegistro:string = "";
  InfoUsuario = [];
  constructor(private serviciosService:ServiciosService,private router: Router) { }

  // funcion de inicio de la pagina
  ngOnInit() {
    //Se va a la parte superior de la pantalla
    window.scroll(0,0);
    //Se cargan los departamentos
    this.serviciosService.getDepartamentos()
    .subscribe((data:[])=>{
      this.ListaDepartamentos = data;
    });
  }

  //-----------------------------------------------------------------------
  // Funcion para cargar la informacion de las ciudades del departamento
  //-----------------------------------------------------------------------
  CambioDepartamento()
  {
    if(this.DepartamentoRegistro != "-1")
    {
      //Se consulta las ciudades
      this.serviciosService.getCiudadesDepartamentos(this.DepartamentoRegistro)
      .subscribe((data:[])=>{
        this.ListaCiudades = data;
        this.CiudadRegistro = "-1";
      });
    }
    else{
      this.CiudadRegistro = "-1";
      this.ListaCiudades = [];
      
    }
  }
  //-----------------------------------------------------------
  // Función para loguerse
  //-----------------------------------------------------------
  Registro()
  {
    // Se captura la informacion del local storage
    var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var CodigoSap = InfoCliente[0]['CodigoSap'];

    // Se crean las variables
    var Salida = [];
    var SalidaUsuario = [];

    // Se verifica que los campos esten llenos
    if(this.TipoDocumentoRegistro =="-1"|| this.IdentificacionRegistro ==null ||
       this.NombresRegistro =="" || this.ApellidosRegistro =="" || 
       this.CelularRegistro == null || this.DepartamentoRegistro =="-1" || this.CiudadRegistro == "-1" || 
       this.DireccionRegistro == "" || this.EmailRegistro == "" || this.ClaveRegistro =="" || this.ClaveComfirma == ""
       )
    {
      Swal.fire({
        title: 'Alerta',
        text:'Existen campos vacios por favor verifique.',
        icon:'warning'
      });
      return;
    }

    //------------------------------------------------------------------------
    //Validaciones de campos
    //------------------------------------------------------------------------
    //Se valida el Email
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.EmailRegistro)){
    } else {
      Swal.fire({
        title: 'Alerta',
        text: 'La dirección de correo electrónico es inválida.',
        icon: 'warning'
      });
      return;
    }
    //Se verifican las contraseñas
    if(this.ClaveRegistro != this.ClaveComfirma)
    {
      Swal.fire({
        title: 'Alerta',
        text:'Las contraseñas no coinciden, por favor verifique.',
        icon:'warning'
      });
      return;
    }
    //Se verifica si el telefono fijo esta vacio
    if(this.TelefonoRegistro == null)
    {
      this.TelefonoRegistro == 0;
    }

    //Se valida si el correo ya esta registrado
    this.serviciosService.getVerificaUsuario(this.EmailRegistro)
    .subscribe((data:[])=>{
      SalidaUsuario = data;
      // Se captura la verificacion
      var VerificaUsuario = SalidaUsuario[0]['Verifica'];
      // Se verifica que no exista el usuario
      if(VerificaUsuario == "0")
      {
        var InfoRegistro = [];
        InfoRegistro.push({"TipoDocumento":this.TipoDocumentoRegistro,"Identificacion":this.IdentificacionRegistro,"Nombres":this.NombresRegistro,"Apellidos":this.ApellidosRegistro,"Telefono":this.TelefonoRegistro,"Celular":this.CelularRegistro,"Departamento":this.DepartamentoRegistro,"Ciudad":this.CiudadRegistro,"Direccion":this.DireccionRegistro,"Correo":this.EmailRegistro,"Clave":this.ClaveRegistro,"CodigoSap":CodigoSap});
        //Se realiza el consumo del servicio para el registro
        this.serviciosService.getRegistro(InfoRegistro)
        .subscribe((data:[])=>{
          Salida = data;

          //Se captura el error
          var VerificaSalida = Salida['Error'];

          //Se verifica la respuesta
          if(VerificaSalida =="0")
          {
            Swal.fire({
              text:'Se ha registrado con éxito en nuestro portal.',
              icon:'success'
            });

            this.LoginUsuario();
          }
          else{
            
            Swal.fire({
              title: 'Alerta',
              text:'Se presento un error, por favor intente de nuevo.',
              icon:'warning'
            });
          }
        });
      }
      else{
        Swal.fire({
          title: 'Alerta',
          text:'El correo ya se encuentra registrado.',
          icon:'warning'
        });
      }
    });
  }

  //-----------------------------------------------------------
  // Función para loguerse
  //-----------------------------------------------------------
  LoginUsuario(){
    //Se verifica que los dos campos este llenos
    if(this.EmailRegistro !="" && this.ClaveRegistro !="")
    {
      // Se captura la informacion del local storage
      var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      var CodigoSap = InfoCliente[0]['CodigoSap'];
      var Descuento = InfoCliente[0]['Descuento'];
      var InfoLogin = [];
      InfoLogin.push({"Usuario":this.EmailRegistro,"Clave":this.ClaveRegistro})
      //Se llama el servicio de login
      this.serviciosService.getLogin(InfoLogin)
      .subscribe((data:[])=>{
        this.InfoUsuario = data;
        //Se verifica si hay registros
        var Verifica:number = this.InfoUsuario.length;

        //Se veririfica la respuesta
        if(Verifica > 0){
          // Se limpia la info del cliente
          var InfoCliente = [{"Id":this.InfoUsuario[0]["IdUsuario"],"CodigoSap":this.InfoUsuario[0]["CodigoSap"],"Descuento":Descuento,"Nombres":this.InfoUsuario[0]["Nombres"],"Apellidos":this.InfoUsuario[0]["Apellidos"],"Identificacion":this.InfoUsuario[0]["Identificacion"],"Celular":this.InfoUsuario[0]["Celular"],"Login":1,"Correo":this.InfoUsuario[0]["Correo"]}]
          localStorage.removeItem("InfoCliente");
          localStorage.setItem("InfoCliente",JSON.stringify(InfoCliente));
          //Se actualiza el token
          localStorage.removeItem("Token");
          localStorage.setItem("Token",CryptoJS.AES.encrypt(this.InfoUsuario[0]["Token"].trim(),"Marpico2021*").toString());
          //Se recarga la pagina
          this.router.navigate(['home']);          
        }
        else{
          alert("Credenciales incorrectas por favor verifique.")
        }
      });
    }
  }
}
