import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-productos-busqueda',
  templateUrl: './productos-busqueda.component.html',
  styleUrls: ['./productos-busqueda.component.css']
})

export class ProductosBusquedaComponent implements OnInit {

  // Se crean las variables globales
  CriterioBusqueda:string = "";
  ListaProductos = [];
  ListaMarcas = [];
  ListaSubCategoria = [];
  ListaFiltros=[];
  DescuentoCliente:number = 0;
  NumProductos:number;
  Orden:number = 0;
  p:number = 1;

  constructor(private serviciosService:ServiciosService, private activatedRoute:ActivatedRoute, private router:Router) {
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
   }

  // Funcion de inicio de la pagina
  ngOnInit() {

    //Se captura el parametro de busqueda
    this.CriterioBusqueda = this.activatedRoute.snapshot.params['Criterio'];

    // Se realiza la consulta de los productos del filtro
    this.serviciosService.getProductosBusqueda(this.CriterioBusqueda.toUpperCase(),this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
      this.NumProductos = this.ListaProductos.length;

    });

    // Se realiza la consulta de las subcategorias
    this.serviciosService.getSubcategoriasBusqueda(this.CriterioBusqueda.toUpperCase())
    .subscribe((data:[])=>
    {
      this.ListaSubCategoria = data;
    });

    //se realiza la consulta de las marcas de la busqueda
    this.serviciosService.getMarcasBusqueda(this.CriterioBusqueda.toUpperCase())
    .subscribe((data:[])=>{
      this.ListaMarcas = data;
    });

    // Se captura la informacion del local storage
    var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
  }

  //--------------------------------------------------------------------------------
  //Funcion para agregar filtros
  //--------------------------------------------------------------------------------
  AgregarFiltro(Id:string,Descripcion:string,Tipo:string){
    // Se verifica si el registro ya existe en el filtro
    var Verifica = this.ListaFiltros.find( Registro => Registro.Nombre == Descripcion );

    //Si el registro no existe se agrega al array
    if(Verifica == undefined)
    {
      // Se agrega el regitro
      this.ListaFiltros.push({Id:Id,Nombre:Descripcion,Tipo:Tipo});
      
      //Se crean las variables
      var SubCategorias = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'SubCategoria')
        {
          if(SubCategorias == "")
          {
            SubCategorias = this.ListaFiltros[Registro].Id;
          }
          else
          {
            SubCategorias = SubCategorias +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      // se va a la primera pagina
      this.p = 1;

      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosBusqueda(this.CriterioBusqueda.toUpperCase(),SubCategorias,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }

  //------------------------------------------------
  //Funcion para remover filtros
  //------------------------------------------------
  RemoverFiltro(Id:string)
  {
    //Se remueve el filtro seleccionado
    this.ListaFiltros = this.ListaFiltros.filter(function(ListaFiltros){
      return ListaFiltros.Id !==Id;
    });

    //Se crean las variables del servicio
    var SubCategorias = "";
    var Marcas = "";

    // Se recorren los registros del array
    for(var Registro in this.ListaFiltros)
    {
      //Se capturan los registros
      var TipoFiltro = this.ListaFiltros[Registro].Tipo;

      //Se verifica el tipo para agregarlo a la consulta
      if(TipoFiltro == 'SubCategoria')
      {
        if(SubCategorias == "")
        {
          SubCategorias = this.ListaFiltros[Registro].Id;
        }
        else
        {
          SubCategorias = SubCategorias +','+ this.ListaFiltros[Registro].Id;
        }
      }
      if(TipoFiltro == 'Marca')
      {
        if(Marcas == "")
        {
          Marcas = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
        }
      }
    }
    // Se va a la primera pagina
    this.p = 1;
    //Se realiza la busqueda con el filtro definido
    this.serviciosService.getFiltroProductosBusqueda(this.CriterioBusqueda.toUpperCase(),SubCategorias,Marcas,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

  //------------------------------------------------
  //Funcion para borrar todos los filtros
  //------------------------------------------------
  BorrarFiltros()
  {
    //Se limpian todos los filtros agregados
    this.ListaFiltros = [];
    // Se va a la primera pagina
    this.p = 1;
    //Se realiza la consulta de los productos
    this.serviciosService.getProductosBusqueda(this.CriterioBusqueda.toUpperCase(),this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

  //----------------------------------------------------------------
  //Funcion para ordenar los registros
  //----------------------------------------------------------------
  Ordenamiento()
  {
    //Se verifica si hay filtros
    var NumFiltros = this.ListaFiltros.length;

    if(NumFiltros == 0)
    {
      //Se realiza la consulta de los productos
      this.serviciosService.getProductosBusqueda(this.CriterioBusqueda.toUpperCase(),this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
    else{
      //Se crean las variables del servicio
      var Secciones = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'Seccion')
        {
          if(Secciones == "")
          {
            Secciones = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Secciones = Secciones +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      // Se va a la primera pagina
      this.p = 1;
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosBusqueda(this.CriterioBusqueda.toUpperCase(),Secciones,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }
}
