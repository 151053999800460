import { Component, OnInit } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  // Se crean las variables globales
  ListaCatalogos = [];
  ListaLegal = [];

  constructor(private serviciosService:ServiciosService) { }

  //Funcion Inicial
  ngOnInit() {

    //Se realiza la consulta de los catalogos
    this.serviciosService.getCatalogos()
    .subscribe((data:[])=>{
      this.ListaCatalogos = data;
    });

    //Se consultan la lista legal
    this.serviciosService.getListaLegal()
    .subscribe((data:[])=>{
      this.ListaLegal = data;
    });
  }
}
