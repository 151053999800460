import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as CryptoJS  from 'crypto-js';

@Component({
  selector: 'app-proceso-compra',
  templateUrl: './proceso-compra.component.html',
  styleUrls: ['./proceso-compra.component.css']
})
export class ProcesoCompraComponent implements OnInit {

  //Se crean las variables globales
  Departamento:string = "-1";
  Ciudad:string = "-1";
  Email:string ="";
  Direccion:string="";
  Email2:string = "";
  Identificacion:number;
  TipoDocumento:string="-1";
  Nombres:string ="";
  Apellidos:string ="";
  Telefono:number;
  CodigoSap:string="";
  IdUsuario:string ="";
  Login:string="";
  SelectDireccion = "-1";
  Direcciones=[];

  //Navegacion
  Step1:boolean = true;
  Step2:boolean = false;
  Step3:boolean = false;
  Step4:boolean = false;
  CodigoEliminar:string;
  DescripcionEliminar:string;
  Pedido = [];
  Items:number = 0;
  SubTotal:number=0;
  Ahorro:number=0;
  Total:number=0;
  Iva:number=0;
  
  //Variables Modal Item
  ImagenEditar:string;
  CodigoEditar:string;
  DescripcionEditar:string;
  ReferenciaEditar:string;
  CantidadEditar:number;
  PrecioEditar:number;
  Inventario:number;
  ListaEscalasProducto = [];
  NumEscalas:number;
  DescuentoCliente:number = 0;
  ListaDepartamentos = [];
  ListaCiudades = [];

  //Variables para el registro o Inicio de secsión
  Usuario:string ="";
  Clave:string = "";
  UsuarioRegistro:string = "";
  ClaveRegistro:string = "";
  ClaveComfirma:string = "";
  NombresRegistro:string = "";
  ApellidosRegistro:string = "";
  TipoDocumentoRegistro:string ="-1";
  IdentificacionRegistro:number;
  DepartamentoRegistro:string ="-1";
  CiudadRegistro:string = "-1";
  DivRegistro:boolean  = false;
  DivLogin:boolean = false;
  DivLoginRegistro:boolean = true;
  DivRecordarClave:boolean = false;
  TelefonoRegistro:number;
  CelularRegistro:number;
  DireccionRegistro:string = "";
  EmailRegistro:string = "";
  InfoUsuario = [];
  NombresUsuRegistrado:string = "";
  ApellidosUsuRegistrado:string = "";
  IdentificacionUsuRegistrado:string = "";
  TelefonoUsuRegistrado:string = "";

  constructor(private serviciosService:ServiciosService,private spinnerService: NgxSpinnerService,private router: Router) { }

  async ngOnInit() {
    //Se va a la parte superior de la pantalla
    window.scroll(0,0);
    this.spinnerActivo();
    //Se cargan los departamentos
    this.serviciosService.getDepartamentos()
    .subscribe((data:[])=>{
      this.ListaDepartamentos = data;
    });

     //Se captura la informacion del pedido de local storage
     this.Pedido = JSON.parse(localStorage.getItem("Pedido"));

     // Se captura la informacion de cliente local storage
     var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
     this.CodigoSap = InfoCliente[0]['CodigoSap'];
     this.IdUsuario = InfoCliente[0]['Id'];
     this.Login=InfoCliente[0]['Login'];
     this.NombresUsuRegistrado = InfoCliente[0]['Nombres'];
     this.ApellidosUsuRegistrado= InfoCliente[0]['Apellidos'];
     this.IdentificacionUsuRegistrado = InfoCliente[0]['Identificacion'];
     this.TelefonoUsuRegistrado = InfoCliente[0]['Celular'];

      //Se realiza la consulta de los descuentos del cliente
      //Productivo
      const DescuentoCliente = await this.serviciosService.getDescuentoCliente("200000023").toPromise();
      //Calidad
      //const DescuentoCliente = await this.serviciosService.getDescuentoCliente("200000015").toPromise();
      this.DescuentoCliente = DescuentoCliente[0]['Descuento']/100;

     //Se llenan los campos del formulario de pago
     this.Identificacion =  parseInt(this.IdentificacionUsuRegistrado);
     this.Nombres = this.NombresUsuRegistrado;
     this.Apellidos = this.ApellidosUsuRegistrado;
     this.TipoDocumento = "1";
     this.Telefono =  parseInt(this.TelefonoUsuRegistrado);


     //Se verifica si esta logueado para consultar las Direcciones
     if(this.Login=="1")
     {
       this.Email = this.Login=InfoCliente[0]['Correo'];
       this.Email2 = this.Login=InfoCliente[0]['Correo'];
       //Se realiza la consulta de las direcciones
       this.serviciosService.getDirecciones(this.IdUsuario)
       .subscribe((data:[])=>{
         this.Direcciones = data;
       });
     }

     //Se crea la variable del pedido verifica
     var PedidoVerifica = [];

     //Se recorren todos los registro para extraer el total total
     for(var Registro in this.Pedido)
     {
       //Se crean las variables para cada registro
        var CantInventario:number = 0;
        var CantidadPedido:number =  this.Pedido[Registro].Cantidad;
        var CodigoSap = this.Pedido[Registro].Codigo;

        //Se verifica el inventario del producto y se captura la cantidad disponible
        const InfoInventario =  await this.serviciosService.getInventarioProducto(CodigoSap).toPromise();
        CantInventario = InfoInventario[0]["Cantidad"];

        //Se verifica que la cantidad pedida sea menor a la cantidad en el inventario
        if(CantidadPedido < CantInventario)
        {
          //Se consulta el descuento del producto
          var SalidaDescuento:any = [];
          SalidaDescuento = await this.serviciosService.getEscalasProducto(CodigoSap).toPromise();
          
          //Se estable el numero de descuentos y el valor del descuento
          var NumEscalas = SalidaDescuento.length;

          var ValEscala:number = 0;
          //Se agrega la informacion del producto al local
          if(NumEscalas > 0)
          {
            //Se recorren las escalas del producto
            for(var Registro2 in SalidaDescuento)
            {
              //Se captura la cantidad de la escala
              var CantEscala = SalidaDescuento[Registro2].Cantidad;
              if(CantidadPedido >= CantEscala)
              {
                ValEscala = SalidaDescuento[Registro2].Descuento;
              }
            }
          }
          //Se actualiza el descuento del producto
          var SalidaProducto:any = [];
          SalidaProducto = await this.serviciosService.getInfoProducto(CodigoSap).toPromise();
          var PrecioNuevo = SalidaProducto[0]["Precio"];
          var IvaNuevo = SalidaProducto[0]["Iva"]/100;
        
          //Se ajuste el Item que cambio
          this.Pedido.map(function(Registro3){
            if(Registro3.Codigo == CodigoSap){
              Registro3.DesEscala = ValEscala;
              Registro3.Precio = PrecioNuevo;
              Registro3.SubTotal = PrecioNuevo*CantidadPedido;
              Registro3.Iva = IvaNuevo;
            }
          });

          //Se filtra el Array para quitar el producto del pedido
          PedidoVerifica.push(this.Pedido[Registro]);
        }
     }
    this.Pedido = [];
    this.Pedido = PedidoVerifica;
    localStorage.removeItem("Pedido");
    localStorage.setItem("Pedido",JSON.stringify(this.Pedido));

    //Se recorren todos los registro para extraer el total total
    for(var Registro in this.Pedido)
    {
      //Variables del detalle
      var SubTotalDetalle = this.Pedido[Registro].SubTotal;
      var AhorroDetalle:number = 0;
      var IvaDetalle:number = 0;
      var TotalDetalle:number = 0;
      var DescuentoProducto = this.Pedido[Registro].DesEscala;
      var CantidadDetalle =this.Pedido[Registro].Cantidad;
      var PrecioDetalle =this.Pedido[Registro].Precio;
      var PorIva = this.Pedido[Registro].Iva;

      SubTotalDetalle = SubTotalDetalle * (1 - (this.DescuentoCliente)*-1);
      SubTotalDetalle = SubTotalDetalle * (1 - (DescuentoProducto/100)*-1);

      AhorroDetalle = Math.round((PrecioDetalle * CantidadDetalle) - SubTotalDetalle);
      IvaDetalle = Math.round(SubTotalDetalle * PorIva);
      TotalDetalle = Math.round(SubTotalDetalle + IvaDetalle);

      this.SubTotal = this.SubTotal + Math.round(SubTotalDetalle);
      this.Ahorro = this.Ahorro + AhorroDetalle;
      this.Iva = this.Iva + IvaDetalle
      this.Total = this.Total + TotalDetalle;
    }
    this.spinnerInactivo();
    //Se verifica si hay registro en el LocalStorage
    if(JSON.parse(localStorage.getItem("Pedido")) == null)
    {
      this.Items = 0;
    }
    else
    {
      var Pedido = [];
      Pedido = JSON.parse(localStorage.getItem("Pedido"))
      this.Items = Pedido.length;
    }
  }

  //---------------------------------------------------------
  // Funcion para visualizar las pantallas
  //---------------------------------------------------------
  Visualizar(Id:string)
  {
    if(Id=="1")
    {
      window.scroll(0,0);
      this.Step1 = true;
      this.Step2 = false;
      this.Step3 = false;
      this.Step4 = false;
    }
    else if(Id=="2")
    {     
      window.scroll(0,0); 
      this.Step1 = false;
      this.Step2 = true;
      this.Step3 = false;
      this.Step4 = false;
    }
    else if(Id=="3")
    {      
      //Se verifica que los campos obligatorios esten seleccionados
      if(this.SelectDireccion == "-1")
      {
        Swal.fire({
          title: 'Alerta',
          text: 'Debe seleccionar una dirección.',
          icon: 'warning'
        });
        return;
      }
      window.scroll(0,0);
      this.Step1 = false;
      this.Step2 = false;
      this.Step3 = true;
      this.Step4 = false;

    }
    else if(Id=="4")
    {      
      //Se captura el check de terminos y condiciones y manejo de datos
      var Terminos =(<HTMLInputElement>document.getElementById('AccTerminos')).checked;
      var Datos =(<HTMLInputElement>document.getElementById('AccDatos')).checked;

      //Se valida que esten seleccionados lo check
      if(!Terminos || !Datos)
      {
        Swal.fire({
          title: 'Alerta',
          text: 'Debe confirmar que acepta el manejo de datos personales y terminos y condiciones.',
          icon: 'warning'
        });
        return;
      }
      
      window.scroll(0,0);
      this.Step1 = false;
      this.Step2 = false;
      this.Step3 = false;
      this.Step4 = true;
    }
  }
  //-------------------------------------------------------------
  // Funcion para cargar la información en el modal de eliminar
  //-------------------------------------------------------------
  ModalEliminar(Codigo:string,Descripcion:string)
  {
    this.CodigoEliminar = Codigo;
    this.DescripcionEliminar = Descripcion;
  }

  //---------------------------------------------------------
  // Funcion para eliminar item del carrito de compras
  //---------------------------------------------------------
  EliminarItem(Codigo:string)
  {
    //Se remueve el filtro seleccionado
    this.SubTotal = 0;
    this.Ahorro = 0;
    this.Total = 0;
    this.Iva=0;
    this.Pedido = this.Pedido.filter(function(Pedido){
      return Pedido.Codigo !== Codigo;
    });
    // Se limpia el local storage
    localStorage.removeItem("Pedido");

    // Se agrega la nueva informacion
    localStorage.setItem("Pedido",JSON.stringify(this.Pedido));
    //Se verifica si hay registro en el LocalStorage
    if(JSON.parse(localStorage.getItem("Pedido")) == null)
    {
      this.Items = 0;
    }
    else
    {
      var Pedido = [];
      Pedido = JSON.parse(localStorage.getItem("Pedido"));
      this.Items = Pedido.length;

      //Se recorren todos los registro para extraer el total total
     for(var Registro in this.Pedido)
     {
        //Variables del detalle
        var SubTotalDetalle = this.Pedido[Registro].SubTotal;
        var AhorroDetalle:number = 0;
        var IvaDetalle:number = 0;
        var TotalDetalle:number = 0;
        var DescuentoProducto = this.Pedido[Registro].DesEscala;
        var CantidadDetalle =this.Pedido[Registro].Cantidad;
        var PrecioDetalle =this.Pedido[Registro].Precio;
        var PorIva = this.Pedido[Registro].Iva;

        SubTotalDetalle = SubTotalDetalle * (1 - (this.DescuentoCliente)*-1);
        SubTotalDetalle = SubTotalDetalle * (1 - (DescuentoProducto/100)*-1);

        AhorroDetalle = Math.round((PrecioDetalle * CantidadDetalle) - SubTotalDetalle);
        IvaDetalle = Math.round(SubTotalDetalle * PorIva);
        TotalDetalle = Math.round(SubTotalDetalle + IvaDetalle);

        this.SubTotal = this.SubTotal + Math.round(SubTotalDetalle);
        this.Ahorro = this.Ahorro + AhorroDetalle;
        this.Iva = this.Iva + IvaDetalle
        this.Total = this.Total + TotalDetalle;
     }
    }
  }
  //-------------------------------------------------------------
  // Funcion para cargar la información en el modal de eliminar
  //-------------------------------------------------------------
  ModalEditar(Imagen:string,Codigo:string,Descripcion:string,Referencia:string,Cantidad:number,Precio:number)
  {
    //Se establecen las variables de la edicion
    var InfoInventario = [];
    this.ImagenEditar = Imagen;
    this.CodigoEditar = Codigo;
    this.DescripcionEditar = Descripcion;
    this.ReferenciaEditar = Referencia;
    this.CantidadEditar = Cantidad;
    this.PrecioEditar = Precio;

    // Se realiza la consulta del inventario
    this.serviciosService.getInventarioProducto(this.CodigoEditar)
    .subscribe((data:[])=>{
      InfoInventario = data;
      this.Inventario = InfoInventario[0]['Cantidad'];
    });

    //Se realiza la consulta de las escalas del producto
    this.serviciosService.getEscalasProducto(this.CodigoEditar)
    .subscribe((data:[])=>{
      this.ListaEscalasProducto = data;
      this.NumEscalas = this.ListaEscalasProducto.length;
    });
  }
  //-------------------------------------------------------------
  // Funcion para cargar la información en el modal de eliminar
  //-------------------------------------------------------------
  GuardarCambiosItem()
  {
    //Se crean las variables
    var Codigo = this.CodigoEditar;
    var Cantidad = this.CantidadEditar;
    var Precio = this.PrecioEditar;
    var SubTotal = Cantidad * Precio;

    // Se verifica que la cantidad sea mayor a 0
    if(Cantidad > 0)
    {
      //Se Verifica el stock 
      if(Cantidad < this.Inventario)
      {
        var ValEscala:number = 0;
        //Se agrega la informacion del producto al local
        if(this.NumEscalas > 0)
        {
          //Se recorren las escalas del producto
          for(var Registro in this.ListaEscalasProducto)
          {
            //Se captura la cantidad de la escala
            var CantEscala = this.ListaEscalasProducto[Registro].Cantidad;
            if(Cantidad >= CantEscala)
            {
              ValEscala = this.ListaEscalasProducto[Registro].Descuento;
            }
          }
        }
        //Se ajuste el Item que cambio
        this.Pedido.map(function(Registro){
          if(Registro.Codigo == Codigo){
            Registro.Cantidad = Cantidad;
            Registro.SubTotal = SubTotal;
            Registro.DesEscala = ValEscala;
          }
        });

        // Se limpia el local storage
        localStorage.removeItem("Pedido");

        // Se agrega la nueva informacion
        localStorage.setItem("Pedido",JSON.stringify(this.Pedido));  

        //Se limpian las variables
        this.SubTotal = 0;
        this.Ahorro = 0;
        this.Total = 0;
        this.Iva=0;

        //Se recorren todos los registro para extraer el total total
        for(var Registro in this.Pedido)
        {
          //Variables del detalle
          var SubTotalDetalle = this.Pedido[Registro].SubTotal;
          var AhorroDetalle:number = 0;
          var IvaDetalle:number = 0;
          var TotalDetalle:number = 0;
          var DescuentoProducto = this.Pedido[Registro].DesEscala;
          var CantidadDetalle =this.Pedido[Registro].Cantidad;
          var PrecioDetalle =this.Pedido[Registro].Precio;
          var PorIva = this.Pedido[Registro].Iva;

          SubTotalDetalle = SubTotalDetalle * (1 - (this.DescuentoCliente)*-1);
          SubTotalDetalle = SubTotalDetalle * (1 - (DescuentoProducto/100)*-1);

          AhorroDetalle = Math.round((PrecioDetalle * CantidadDetalle) - SubTotalDetalle);
          IvaDetalle = Math.round(SubTotalDetalle * PorIva);
          TotalDetalle = Math.round(SubTotalDetalle + IvaDetalle);

          this.SubTotal = this.SubTotal + Math.round(SubTotalDetalle);
          this.Ahorro = this.Ahorro + AhorroDetalle;
          this.Iva = this.Iva + IvaDetalle
          this.Total = this.Total + TotalDetalle;
        }
      } 
      else{
        Swal.fire({
          title: 'Alerta',
          text: 'No hay stock suficiente.',
          icon: 'warning'
        });
      }     
    }
    else{
      Swal.fire({
        title: 'Alerta',
        text: 'La cantidad debe ser mayor a 0, por favor verifique.',
        icon: 'warning'
      });
    }
  }
  //-----------------------------------------------------------------------
  // Funcion para cargar la informacion de las ciudades del departamento
  //-----------------------------------------------------------------------
  CambioDepartamento()
  {
    if(this.Departamento != "-1")
    {
      //Se consulta las ciudades
      this.serviciosService.getCiudadesDepartamentos(this.Departamento)
      .subscribe((data:[])=>{
        this.ListaCiudades = data;
        this.Ciudad = "-1";
      });
    }
    else{
      this.Ciudad = "-1";
      this.ListaCiudades = [];
      
    }
  }
  //-----------------------------------------------------------------------
  // Funcion para Iniciar el proceso de pago
  //-----------------------------------------------------------------------
  InicioPago()
  {
    // Se crean las variables
    var RtaPedidoCab = [];

    //Se verifica si esta logueado Y se valida que esten llenos los campos
    if(this.Login == "0")
    {
      // Se realiza la validacion de las variables
      if(this.Email == "" || this.Email2 =="" || this.Identificacion == null || this.TipoDocumento =="-1" || this.Nombres =="" || this.Apellidos =="" || this.Telefono == 0 || this.Departamento== "-1" || this.Ciudad=="-1" || this.Direccion == "")
      {
        Swal.fire({
          title: 'Alerta',
          text: 'Existen campos vacios porfavor verifique.',
          icon: 'warning'
        });
        return;
      }
    }else{
      if(this.Email == "" || this.Email2 =="" || this.Identificacion == null || this.TipoDocumento =="-1" || this.Nombres =="" || this.Apellidos =="" || this.Telefono == 0 || this.SelectDireccion =="-1")
      {
        Swal.fire({
          title: 'Alerta',
          text: 'Existen campos vacios porfavor verifique.',
          icon: 'warning'
        });
        return;
      }

      //------------------------------------------------------------------------
      //Validaciones de campos
      //------------------------------------------------------------------------
      //Se valida el Email
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email)){
      } else {
        Swal.fire({
          title: 'Alerta',
          text: 'La dirección de correo electrónico es inválida.',
          icon: 'warning'
        });
       return;
      }
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email2)){
      } else {
        Swal.fire({
          title: 'Alerta',
          text: 'La dirección de correo electrónico es inválida.',
          icon: 'warning'
        });
       return;
      }

      // Se asignan los valores de la direccion
      var VerificaDireccion = this.Direcciones.find(Registro => Registro.IdDireccion == this.SelectDireccion);
      
      //Se asignan los valores a los campos
      this.Direccion = VerificaDireccion["Direccion"];
      this.Departamento = VerificaDireccion["IdDepartamento"];
      this.Ciudad = VerificaDireccion["IdCiudad"];
    }
    // Se activa el spinner
    this.spinnerActivo();
    // Se crean las variables para el manejo del token
    var RespuestaToken = [];
    var Verifica:string = "";
    var Token:string = "";
    //Se realiza la consulta del token
    this.serviciosService.getConsultaToken()
    .subscribe((data:[])=>{
      // Se captura la respuesta
      RespuestaToken = data;
      Verifica = RespuestaToken['Error'];
      Token = RespuestaToken['Token'];

      //Se verifica si se presento algun error
      if(Verifica == "0")
      {
        //Se realiza la creacion de la cabecera del pedido
        this.serviciosService.CrearPedidoCabecera(this.IdUsuario,this.SubTotal.toString(),this.Iva.toString(),this.Total.toString(),this.CodigoSap,this.NombresUsuRegistrado,this.ApellidosUsuRegistrado,this.Direccion.replace('#','@@'),this.Departamento,this.Ciudad,this.TelefonoUsuRegistrado,this.Email,this.IdentificacionUsuRegistrado,this.Ahorro.toString())
        .subscribe((data:[])=>{
          //Se captura la respuesta
          RtaPedidoCab = data;
          var VerificaPed =RtaPedidoCab["Error"];
          var IdPedido = RtaPedidoCab["Id"];
          var DescPedido = "Pedido toolShack Numero "+ IdPedido;
          var DetallesPedido = [];

          //Se verifica si no hay error
          if(VerificaPed == "0")
          {
            //Se recorren todos los registro para añadir el Id del pedido
            for(var Registro in this.Pedido)
            { 
              //Variables del detalle
              var SubTotalP = this.Pedido[Registro].SubTotal;
              var AhorroP:number = 0;
              var IvaP:number = 0;
              var TotalP:number = 0;
              var DescuentoProducto = this.Pedido[Registro].DesEscala;
              var CantidadDetalle =this.Pedido[Registro].Cantidad;
              var PrecioDetalle =this.Pedido[Registro].Precio;
              var PorIva = this.Pedido[Registro].Iva;

              SubTotalP = SubTotalP * (1 - (this.DescuentoCliente)*-1);
              SubTotalP = SubTotalP * (1 - (DescuentoProducto/100)*-1);
              SubTotalP = Math.round(SubTotalP);
              AhorroP = Math.round((PrecioDetalle * CantidadDetalle) - SubTotalP);
              IvaP = Math.round(SubTotalP * PorIva);
              TotalP = Math.round(SubTotalP + SubTotalP);

              DetallesPedido.push({"IdPedido":IdPedido,"Codigo":this.Pedido[Registro].Codigo,"Precio":this.Pedido[Registro].Precio,"Cantidad":this.Pedido[Registro].Cantidad,"Unidad":this.Pedido[Registro].Unidad,"SubTotal":SubTotalP,"Ahorro":AhorroP,"Iva":IvaP,"Total":TotalP})
            }
            //Se realiza la creacion del pedido
            this.serviciosService.CrearPedidoDetalles(DetallesPedido)
            .subscribe((data:[])=>{
              var RespuestDetallesPedido = [];
              RespuestDetallesPedido = data;
              var VerificaRespDetPedido = RespuestDetallesPedido['Error'];
              //Se verifica la respuesta del servicio que crea los detalles del pedido
              if(VerificaRespDetPedido == "0")
              {
                //Se realiza el consumo del servicio que crea el pago en la sonda
                this.serviciosService.getAlmacenarPagoSonda(Token,IdPedido,this.Identificacion.toString(),'40004163')
                .subscribe((data:[])=>{
                  //Se captura la salida
                  var RespuestaPagoSonda = [];
                  RespuestaPagoSonda = data;
                  var VerificaPagoSonda = RespuestaPagoSonda['Error'];

                  // Se verifica si se presento algun error
                  if(VerificaPagoSonda == "0")
                  {
                    // Se realiza el llamado a zonaPagos
                    this.serviciosService.InicioPagoZV(this.Total,this.Iva,IdPedido,DescPedido,this.Email,this.Identificacion.toString(),this.TipoDocumento,this.Nombres,this.Apellidos,this.Telefono)
                    .subscribe((data:[])=>{
                      var InicioPagos = [];
                      var CodigoInicio = [];
                      InicioPagos = data;
                      CodigoInicio = InicioPagos["soap:Envelope"]["soap:Body"][0]["inicio_pagoV2Response"][0]["inicio_pagoV2Result"][0];
                      this.spinnerInactivo();
                      // Se limpia el pedido
                      localStorage.removeItem("Pedido");
                      //Productivo
                      window.location.href ="https://www.zonapagos.com/t_Marpicopas/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                      //Calidad
                      //window.location.href ="https://www.zonapagos.com/t_Marpicosa2/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                      this.router.navigate(['Tracking']);
                    });
                  }
                  else{
                    Swal.fire({
                    title: 'Alerta',
                    text: 'Error al almacenar datos pago.',
                    icon: 'warning'
                    });
                    this.spinnerInactivo();
                  }
                });
              }else{
                Swal.fire({
                  title: 'Alerta',
                  text: 'Error al crear el detalle del pedido, por favor intente mas tarde.',
                  icon: 'warning'
                  });
                this.spinnerInactivo();
              }
            });
          }
          else{
            Swal.fire({
              title: 'Alerta',
              text: 'Error al crear la Cabecera del Pedido.',
              icon: 'warning'
              });
            this.spinnerInactivo();
          }
        });
      }
    });
  }

  //------------------------------------------------------------
  // Función para activar el spinner
  //------------------------------------------------------------
  spinnerActivo(): void {
    this.spinnerService.show();
  }

  //-----------------------------------------------------------
  // Función para inactivar el spinner
  //-----------------------------------------------------------
  spinnerInactivo(): void {
    this.spinnerService.hide();
  }

  //-----------------------------------------------------------
  // Función para ver el Div de registro
  //-----------------------------------------------------------
  VerRegistro(){
    this.DivRegistro = true;
    this.DivLoginRegistro = false;
    this.DivRecordarClave = false;
  }
  //-----------------------------------------------------------
  // Función para ver el Div de registro
  //-----------------------------------------------------------
  VerLogin(){
    this.DivLogin = true;
    this.DivRecordarClave = false;
    this.DivLoginRegistro = false;

    if(this.EmailRegistro !="")
    {
      this.Usuario = this.EmailRegistro;
    }
  }

  //-----------------------------------------------------------
  // Función para ver el Div de registro
  //-----------------------------------------------------------
  AbrirLoginRegistro(){
    this.DivLogin = false;
    this.DivRegistro = false;
    this.DivRecordarClave = false;
    this.DivLoginRegistro = true;
    this.DepartamentoRegistro = "-1";
  }

  //-----------------------------------------------------------
  // Función para loguerse
  //-----------------------------------------------------------
  LoginUsuario(){
     // Se captura la informacion del local storage
     var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
     var DescuentoCliente = InfoCliente[0]['Descuento'];

    //Se verifica que los campos esten llenos
    if(this.Usuario ==""||this.Clave =="")
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios por favor verifique.',
        icon: 'warning'
        });
      return;
    }

    //Se verifica que los dos campos este llenos
    if(this.Usuario !="" && this.Clave !="")
    {
      //Se crean las variables para verificar si existe el usuario
      var VerificaUsuario = [];
      var VerificaRespuesta:string = "";

      // Se verifica si el usuario esta registrado
      this.serviciosService.getVerificaUsuario(this.Usuario)
      .subscribe((data:[])=>{
        VerificaUsuario = data;
        VerificaRespuesta = VerificaUsuario[0]['Verifica'];
        if(VerificaRespuesta=="0")
        {
          Swal.fire({
            title: 'Alerta',
            text: 'El usuario no se encuentra registrado en nuestro portal.',
            icon: 'warning'
          });
        }
        else{
          var InfoLogin = [];
          InfoLogin.push({"Usuario":this.Usuario,"Clave":this.Clave})
          //Se llama el servicio de login
          this.serviciosService.getLogin(InfoLogin)
          .subscribe((data:[])=>{
            this.InfoUsuario = data;
            //Se verifica si hay registros
            var Verifica:number = this.InfoUsuario.length;

            //Se veririfica la respuesta
            if(Verifica > 0){
              var url= window.location.hash;
              // Se limpia la info del cliente
              var InfoCliente = [{"Id":this.InfoUsuario[0]["IdUsuario"],"CodigoSap":this.InfoUsuario[0]["CodigoSap"],"Descuento":DescuentoCliente,"Nombres":this.InfoUsuario[0]["Nombres"],"Apellidos":this.InfoUsuario[0]["Apellidos"],"Identificacion":this.InfoUsuario[0]["Identificacion"],"Celular":this.InfoUsuario[0]["Celular"],"Login":1,"Correo":this.InfoUsuario[0]["Correo"]}]
              localStorage.removeItem("InfoCliente");
              localStorage.setItem("InfoCliente",JSON.stringify(InfoCliente));
              //Se actualiza el token
              localStorage.removeItem("Token");
              localStorage.setItem("Token",CryptoJS.AES.encrypt(this.InfoUsuario[0]["Token"].trim(),"Marpico2021*").toString());
              //Se recarga la pagina
              window.location.reload();
              
            }
            else{
              Swal.fire({
                title: 'Alerta',
                text: 'Credenciales incorrectas por favor verifique.',
                icon: 'warning'
                });
            }
          });
        }
      });
    }
  }

  //-----------------------------------------------------------
  // Función para loguerse
  //-----------------------------------------------------------
  Registro()
  {
    // Se crean las variables
    var Salida = [];
    var SalidaUsuario = [];

    // Se verifica que los campos esten llenos
    if(this.TipoDocumentoRegistro =="-1"|| this.IdentificacionRegistro ==null ||
       this.NombresRegistro =="" || this.ApellidosRegistro =="" ||
       this.CelularRegistro == null || this.DepartamentoRegistro =="-1" || this.CiudadRegistro == "-1" || 
       this.DireccionRegistro == "" || this.EmailRegistro == "" || this.ClaveRegistro =="" || this.ClaveComfirma == ""
       )
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios por favor verifique.',
        icon: 'warning'
        });
      return;
    }
    //Se valida el Email
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.EmailRegistro)){
    } else {
      Swal.fire({
        title: 'Alerta',
        text: 'La dirección de correo electrónico es inválida.',
        icon: 'warning'
      });
      return;
    }
    //Se verifican las contraseñas
    if(this.ClaveRegistro != this.ClaveComfirma)
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Las contraseñas no coinciden, por favor verifique.',
        icon: 'warning'
        });
      return;
    }
    
    //Se verifica si el telefono fijo esta vacio
    if(this.TelefonoRegistro == null)
    {
      this.TelefonoRegistro == 0;
    }

    //Se valida si el correo ya esta registrado
    this.serviciosService.getVerificaUsuario(this.EmailRegistro)
    .subscribe((data:[])=>{
      SalidaUsuario = data;
      // Se captura la verificacion
      var VerificaUsuario = SalidaUsuario[0]['Verifica'];
      // Se verifica que no exista el usuario
      if(VerificaUsuario == "0")
      {
        var InfoRegistro = [];
        InfoRegistro.push({"TipoDocumento":this.TipoDocumentoRegistro,"Identificacion":this.IdentificacionRegistro,"Nombres":this.NombresRegistro,"Apellidos":this.ApellidosRegistro,"Telefono":this.TelefonoRegistro,"Celular":this.CelularRegistro,"Departamento":this.DepartamentoRegistro,"Ciudad":this.CiudadRegistro,"Direccion":this.DireccionRegistro,"Correo":this.EmailRegistro,"Clave":this.ClaveRegistro,"CodigoSap":this.CodigoSap});
        //Se realiza el consumo del servicio para el registro
        this.serviciosService.getRegistro(InfoRegistro)
        .subscribe((data:[])=>{
          Salida = data;

          //Se captura el error
          var VerificaSalida = Salida['Error'];

          //Se verifica la respuesta
          if(VerificaSalida =="0")
          {
            // Se loguea la persona
            this.Usuario = this.EmailRegistro;
            this.Clave = this.ClaveRegistro;

            this.LoginUsuario();
            Swal.fire({
              text: 'Se ha registrado con existo en nuestro portal.',
              icon: 'success'
              });
          }
          else{
            Swal.fire({
              title: 'Alerta',
              text: 'Se presento un error, por favor intente de nuevo.',
              icon: 'warning'
              });
          }
        });
      }
      else{
        // Para el correo ya registrado
        this.DivRecordarClave = true;
        this.DivRegistro = false;
      }
    });
  }

  //-----------------------------------------------------------------------
  // Funcion para cargar la informacion de las ciudades del departamento
  //-----------------------------------------------------------------------
  CambioDepartamentoRegistro()
  {
    if(this.DepartamentoRegistro != "-1")
    {
      //Se consulta las ciudades
      this.serviciosService.getCiudadesDepartamentos(this.DepartamentoRegistro)
      .subscribe((data:[])=>{
        this.ListaCiudades = data;
        this.Ciudad = "-1";
      });
    }
    else{
      this.Ciudad = "-1";
      this.ListaCiudades = [];
    }
  }
  
  //-----------------------------------------------------------------------
  // Funcion para cargar la informacion de las ciudades del departamento
  //-----------------------------------------------------------------------
  RecordarClave()
  {
    var InfoRecordar = [];

    //Se ejecuta el servicio de recordar clave
    this.serviciosService.getRecordarClave(this.EmailRegistro)
    .subscribe((data:[])=>{
      InfoRecordar = data;
      //Se verifica la respuesta 
      var Verifica = InfoRecordar['Error'];

      //Se verifica la respuesta
      if(Verifica =="0")
      {
        Swal.fire({
          text: 'Se ha enviado la contraseña a su correo, por favor verifique.',
          icon: 'success'
          });
      }
      else{
        Swal.fire({
          title: 'Alerta',
          text: 'Se presento un error en el envio del correo, intente de nuevo.',
          icon: 'warning'
          });
      }
    });
  }
}
