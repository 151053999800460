import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { ProductosCatalogoComponent} from './productos-catalogo/productos-catalogo.component';
import { ProductosSeccionComponent} from './productos-seccion/productos-seccion.component';
import { ProductosCategoriaComponent} from './productos-categoria/productos-categoria.component';
import { ProductoDetalleComponent } from './producto-detalle/producto-detalle.component';
import { ProductosBusquedaComponent } from './productos-busqueda/productos-busqueda.component';
import { ProcesoCompraComponent } from './proceso-compra/proceso-compra.component';
import { LoginComponent } from './login/login.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { TrackingComponent } from './tracking/tracking.component';
import { CambioClaveComponent } from './cambio-clave/cambio-clave.component';
import { ComprasRealizadasComponent } from './compras-realizadas/compras-realizadas.component';
import { ExtractoComponent } from './extracto/extracto.component';
import { CentrosServiciosComponent } from './centros-servicios/centros-servicios.component';
import { DetallePedidoComponent } from './detalle-pedido/detalle-pedido.component';
import { ProcesoPagoComponent } from './proceso-pago/proceso-pago.component';
import { RegistroComponent } from './registro/registro.component';
import { LegalComponent } from './legal/legal.component';
import { DevolucionesComponent } from './devoluciones/devoluciones.component';
import { DevolucionDetalleComponent } from './devolucion-detalle/devolucion-detalle.component';
import { ListaDevolucionesComponent } from './lista-devoluciones/lista-devoluciones.component';
import { CrearDevolucionComponent } from './crear-devolucion/crear-devolucion.component';
import { ListaBlogsComponent } from './lista-blogs/lista-blogs.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { RecordarClaveComponent } from './recordar-clave/recordar-clave.component';


const routes: Routes = [
  {path: '',component:HomeComponent},
  {path: 'home',component:HomeComponent},
  {path: 'ProductosCatalogo/:Id', component:ProductosCatalogoComponent},
  {path: 'ProductosSeccion/:Id', component:ProductosSeccionComponent,runGuardsAndResolvers:"paramsChange"},
  {path: 'ProductosCategoria/:Id', component:ProductosCategoriaComponent,runGuardsAndResolvers:"paramsChange"},
  {path: 'ProductoDetalle/:Id', component:ProductoDetalleComponent},
  {path: 'ProductosBusqueda/:Criterio', component:ProductosBusquedaComponent,runGuardsAndResolvers:"paramsChange"},
  {path: 'ProcesoCompra', component:ProcesoCompraComponent},
  {path: 'Login', component:LoginComponent},
  {path: 'MiCuenta',component:MiCuentaComponent},
  {path: 'Tracking',component:TrackingComponent},
  {path: 'CambioClave', component:CambioClaveComponent},
  {path: 'ComprasRealizadas', component:ComprasRealizadasComponent},
  {path: 'Extracto', component:ExtractoComponent},
  {path: 'CentrosServicio',component:CentrosServiciosComponent},
  {path: 'DetallePedido/:Id',component:DetallePedidoComponent},
  {path: 'ProcesoPago/:Id',component:ProcesoPagoComponent},
  {path: 'Registro',component:RegistroComponent},
  {path: 'Legal/:Id', component:LegalComponent},
  {path: 'Devoluciones',component:DevolucionesComponent},
  {path: 'DevolucionDetalle',component:DevolucionDetalleComponent},
  {path: 'ListaDevoluciones', component:ListaDevolucionesComponent},
  {path: 'CrearDevolucion',component:CrearDevolucionComponent},
  {path: 'ListaBlogs',component:ListaBlogsComponent},
  {path: 'DetalleBlog/:Id',component:DetalleBlogComponent},
  {path: 'RecordarClave',component:RecordarClaveComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
