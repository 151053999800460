import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { Router,ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.component.html',
  styleUrls: ['./detalle-pedido.component.css']
})
export class DetallePedidoComponent implements OnInit {

  //Se realiza la creacion de variables globales
  DetallePedido = [];
  InfoPedido =[];
  IdPedido:string = "";
  SubTotal:string = "";
  Iva:string = "";
  Ahorro:string = "";
  Items:number=0;
  Total:string = "";
  MensajePendiente:boolean = false;
  MensajeRechazo:boolean = false;

  constructor(private serviciosService:ServiciosService,private router: Router,private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    // Se captura el Id del pedido
    window.scroll(0,0);
    //Se captura el Id del Catalogo
    this.IdPedido = this.activatedRoute.snapshot.params['Id'];

    // Se realiza la consulta de los detalles del pedido
    this.serviciosService.getDetallePedido(this.IdPedido)
    .subscribe((data:[])=>{
      this.DetallePedido = data;
      this.Items = this.DetallePedido.length;
    });

    //Se reliza la consulta de la informacion del pedido
    this.serviciosService.getInfoPedido(this.IdPedido)
    .subscribe((data:[])=>{
      this.InfoPedido = data;

      //Se esablecen los campos a mostrar
      this.SubTotal = this.InfoPedido[0]["SubTotal"];
      this.Iva = this.InfoPedido[0]["Iva"];
      this.Ahorro = this.InfoPedido[0]["Ahorro"];
      this.Total = this.InfoPedido[0]["Total"];

      //Se verifica el estado del pedido
      if(this.InfoPedido[0]["Estado"] =="888" ||this.InfoPedido[0]["Estado"] =="999")
      {
        this.MensajePendiente = true;
      }
      else if(this.InfoPedido[0]["Estado"] =="1000" || this.InfoPedido[0]["Estado"] =="1002" || this.InfoPedido[0]["Estado"] =="4000")
      {
        this.MensajeRechazo = true;
      }

    });
  }

}
