import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MiCuentaComponent implements OnInit {

  @ViewChild('closebutton', {static: false}) closebutton: ElementRef;

  //Se crean las variables globales
  Identificacion:number;
  Nombres:string = "";
  Apellidos:string = "";
  Telefono:number;
  Celular:number;
  Email:string = "";
  Departamento:string="";
  Ciudad:string="";
  Direccion:string ="";
  InfoUsuario=[];
  Direcciones = [];
  IdDepartamento:string = "";
  IdCiudad:string = "";
  TipoDocumento:string="";
  
  //Variables del modal de modificar Usuario
  ListaDepartamentos = [];
  ListaCiudades = [];
  IdentificacionEdita:number;
  NombresEdita:string = "";
  ApellidosEdita:string = "";
  TelefonoEdita:number;
  CelularEdita:number;
  EmailEdita:string = "";
  DepartamentoEdita:string = "-1";
  CiudadEdita:string = "-1";
  TipoDocumentoEdita:string = "-1";

  //Variables del modal de agregar direccion
  Alias:string ="";
  DireccionAgregar:string = "";
  DatosAdicionales:string ="";
  IdDireccion:string = "";


  constructor(private serviciosService:ServiciosService,private router: Router) { }

  //Funcion Inicial
  ngOnInit() {
    //Se va a la parte superior de la pantalla
    window.scroll(0,0);

    //Se captura el Id del local storage
    var InfoCliente = [];
    InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var Id = InfoCliente[0]["Id"];
    
    //Se llama la funcion de informacion de usuario
    this.serviciosService.getinfoUsuario(Id)
    .subscribe((data:[])=>{
      //Se captura la informacion del usuario
      this.InfoUsuario = data;
      console.log(this.InfoUsuario);

      //Se asignan los valores a las variables
      this.Identificacion = this.InfoUsuario[0]["Identificacion"];
      this.Nombres = this.InfoUsuario[0]["Nombres"];
      this.Apellidos = this.InfoUsuario[0]["Apellidos"];
      this.Email = this.InfoUsuario[0]["Correo"];
      this.Telefono = this.InfoUsuario[0]["TelefonoFijo"];
      this.Celular = this.InfoUsuario[0]["Celular"];
      this.Departamento = this.InfoUsuario[0]["Departamento"];
      this.Ciudad = this.InfoUsuario[0]["Ciudad"];
      this.Direccion = this.InfoUsuario[0]["Direccion"];
      this.IdDepartamento = this.InfoUsuario[0]["IdDepartamento"];
      this.IdCiudad = this.InfoUsuario[0]["IdCiudad"];
      this.TipoDocumento = this.InfoUsuario[0]["TipoDocumento"];
    });

    //Se realiza la consulta de las direcciones
    this.serviciosService.getDirecciones(Id)
    .subscribe((data:[])=>{
      this.Direcciones = data;
    });

    //Se cargan los departamentos
    this.serviciosService.getDepartamentos()
    .subscribe((data:[])=>{
      this.ListaDepartamentos = data;
    });
  }

  //-------------------------------------------------------
  // Función para el cambio de departamento
  //-------------------------------------------------------
  CambioDepartamento()
  {
    if(this.DepartamentoEdita != "-1")
    {
      //Se consulta las ciudades
      this.serviciosService.getCiudadesDepartamentos(this.DepartamentoEdita)
      .subscribe((data:[])=>{
        this.ListaCiudades = data;
        this.CiudadEdita = "-1";
      });
    }
    else{
      this.CiudadEdita = "-1";
      this.ListaCiudades = [];
      
    }
  }

  //-------------------------------------------------------
  // Función para limpiar departamento y ciudad
  //-------------------------------------------------------
  LimpiarModal()
  {
    this.DepartamentoEdita ="-1";
    this.CiudadEdita = "-1";
    this.Alias = "";
    this.DireccionAgregar ="";
    this.DatosAdicionales = "";
  }

  //---------------------------------------------------------------------------
  // Función para cargar la informacion al usuario en los campos del modal
  //---------------------------------------------------------------------------
  AbrirModalUsuario()
  {
    this.IdentificacionEdita =this.Identificacion;
    this.NombresEdita = this.Nombres;
    this.ApellidosEdita = this.Apellidos;
    this.TelefonoEdita = this.Telefono;
    this.CelularEdita = this.Celular;
    this.EmailEdita = this.Email;
    this.DepartamentoEdita = this.IdDepartamento;
    this.CiudadEdita = this.IdCiudad;
    this.TipoDocumentoEdita = this.TipoDocumento;

    //Se cargan los departamentos
    this.serviciosService.getCiudadesDepartamentos(this.IdDepartamento)
    .subscribe((data:[])=>{
      this.ListaCiudades = data;
    });

  }

  //-------------------------------------------------------
  // Función para guardar la informacion en la base de datos
  //-------------------------------------------------------
  ActualizarDatos()
  {
    //Se crea la variable de salida
    var Salida =[];
    //Se captura el Id del local storage
    var InfoCliente = [];
    InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var Id = InfoCliente[0]["Id"];
    var DescuentoCliente = InfoCliente[0]['Descuento'];


    //Se verifican que todos los campos esten llenos
    if( this.IdentificacionEdita == null || this.NombresEdita == "" || this.ApellidosEdita == "" || this.CelularEdita == null ||this.EmailEdita == "" || this.DepartamentoEdita == "-1" || this.CiudadEdita == "-1" || this.TipoDocumentoEdita == "-1")
    {
      Swal.fire({
        title: 'Alerta',
        text:'Existen campos vacios por favor verifique.',
        icon:'warning'
      });
      return;
    }

    //Se verifica si el telefono esta vacio
    if(this.TelefonoEdita == null)
    {
      this.TelefonoEdita = 0;
    }

    //Se realiza el llamado del servicio
    this.serviciosService.getActualizarDatos(Id,this.IdentificacionEdita.toString(),this.NombresEdita,this.ApellidosEdita,this.TelefonoEdita.toString(),this.CelularEdita.toString(),this.EmailEdita,this.DepartamentoEdita,this.CiudadEdita,this.TipoDocumentoEdita)
    .subscribe((data:[])=>{
      Salida = data;
      //Se Captura la respuesta
      var Verifica = Salida["Error"];

      //Se verifica si se presento algun error
      if(Verifica =="0")
      {
        Swal.fire({
          text:'Registro actualizado correctamente.',
          icon:'success'
        });
        //Se captura el Id del local storage
        var InfoClienteLocal = [];
        InfoClienteLocal = JSON.parse(localStorage.getItem("InfoCliente"));
        var Id = InfoClienteLocal[0]["Id"];
        var CodigoSap = InfoClienteLocal[0]["CodigoSap"];
        var Correo = InfoClienteLocal[0]["Correo"];
        // Se limpia la info del cliente
        var InfoCliente = [{"Id":Id,"CodigoSap":CodigoSap,"Descuento":DescuentoCliente,"Nombres":this.NombresEdita,"Apellidos":this.ApellidosEdita,"Identificacion":this.IdentificacionEdita,"Celular":this.CelularEdita,"Login":1,"Correo":Correo}]
        localStorage.removeItem("InfoCliente");
        localStorage.setItem("InfoCliente",JSON.stringify(InfoCliente));
        this.closebutton.nativeElement.click();
        window.location.reload();
      }
      else{
        Swal.fire({
          title: 'Alerta',
          text:'Se presento un error intente de nuevo.',
          icon:'warning'
        });
      }
    });
  }

  //-------------------------------------------------------
  // Función para agregar direcciones
  //-------------------------------------------------------
  AgregarDireccion()
  {
    //Se crea la variable de salida
    var Salida =[];
    //Se captura el Id del local storage
    var InfoCliente = [];
    InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var Id = InfoCliente[0]["Id"];

    //Se verifican que los campos requeridos esten llenos
    if(this.DireccionAgregar ==""||this.DepartamentoEdita=="-1"||this.CiudadEdita=="-1")
    {
      Swal.fire({
        title: 'Alerta',
        text:'Existen campos vacios, por favor verifique.',
        icon:'warning'
      });
      return
    }
    var InfoDireccion = [];
    InfoDireccion.push({"IdUsuario":Id,"Direccion":this.DireccionAgregar,"DatosAdicionales":this.DatosAdicionales,"Departamento":this.DepartamentoEdita,"Ciudad":this.CiudadEdita,"Alias":this.Alias})
    //Se realiza el consumo del servicio
    this.serviciosService.getAregarDireccion(InfoDireccion)
    .subscribe((data:[])=>{

      // Se captura la salida
      Salida = data;

      //Se Captura la respuesta
      var Verifica = Salida["Error"];

      //Se verifica si se presento algun error
      if(Verifica =="0")
      {
        Swal.fire({
          text:'Registro agregado correctamente.',
          icon:'success'
        });
        this.closebutton.nativeElement.click();
        window.location.reload();
      }
      else{
        Swal.fire({
          title: 'Alerta',
          text:'Se presento un error intente de nuevo.',
          icon:'warning'
        });
      }
    })
  }

  //-------------------------------------------------------
  // Función para agregar direcciones
  //-------------------------------------------------------
  AbrirModalModDireccion(IdDireccion:string,Direccion:string,DatosAdicionales:string,IdDepartamento:string,IdCiudad:string,Alias:string)
  {
    //Se limpian los campos del modal
    this.Alias = "";
    this.DireccionAgregar = "";
    this.DatosAdicionales = "";
    this.DepartamentoEdita = "-1";
    this.CiudadEdita = "-1";

    //Se asignan los valores
    this.IdDireccion = IdDireccion;
    this.DireccionAgregar = Direccion;
    this.DatosAdicionales = DatosAdicionales;
    this.DepartamentoEdita = IdDepartamento;
    this.Alias = Alias;

    //Se consulta las ciudades
    this.serviciosService.getCiudadesDepartamentos(this.DepartamentoEdita)
    .subscribe((data:[])=>{
      this.ListaCiudades = data;
      this.CiudadEdita = IdCiudad;;
    });
  }

  //-------------------------------------------------------
  // Función para agregar direcciones
  //-------------------------------------------------------
  ModificarDireccion()
  {
    //Se crean las variables de la respuesta.
    var SalidaActualiza = [];
    var VerificaSalida:string ="";

    // Se verifican que los campos se encuentren llenos
    if(this.IdDireccion =="" || this.DireccionAgregar =="" || this.DatosAdicionales==""||this.DepartamentoEdita==""||this.CiudadEdita ==""|| this.Alias=="")
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios por favor verifique.',
        icon: 'warning'
      });
      return;
    }
    var InfoDireccion = [];
    InfoDireccion.push({"IdDireccion":this.IdDireccion,"Direccion":this.DireccionAgregar,"DatosAdicionales":this.DatosAdicionales,"Departamento":this.DepartamentoEdita,"Ciudad":this.CiudadEdita,"Alias":this.Alias})
    //Se verifica que todos los campos este llenos
    this.serviciosService.getActualizaDireccion(InfoDireccion)
    .subscribe((data:[])=>{
      SalidaActualiza = data;
      VerificaSalida = SalidaActualiza['Error'];

      if(VerificaSalida == "0")
      {
        Swal.fire({
          text:'Registro actualizado correctamente.',
          icon:'success'
        }); 
        this.closebutton.nativeElement.click();
        window.location.reload();
      }
    })
  }
}
