import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-proceso-pago',
  templateUrl: './proceso-pago.component.html',
  styleUrls: ['./proceso-pago.component.css']
})
export class ProcesoPagoComponent implements OnInit {

  //Se realiza la creación de las variables globales
  InfoPedido = [];
  IdPedido:string = "";
  SubTotal:string = "";
  Ahorro:string = "";
  Iva:number;
  Total:number;
  TipoDocumento = "-1";
  Email:string = "";
  Identificacion:number;
  Nombres:string = "";
  Apellidos:string = "";
  Telefono:number;

  //Informacion del Cliente
  DescuentoCliente:number;
  CodigoSap:string = "";
  IdUsuario:string = "";

  constructor(private serviciosService:ServiciosService,private router: Router,private activatedRoute:ActivatedRoute,private spinnerService: NgxSpinnerService) { }

  //Funcion de inici de la pagina
  ngOnInit() {

    // Se captura el Id del pedido
    window.scroll(0,0);
    //Se captura el Id del Catalogo
    this.IdPedido = this.activatedRoute.snapshot.params['Id'];

    // Se captura la informacion del local storage
    var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
    this.CodigoSap = InfoCliente[0]['CodigoSap'];
    this.IdUsuario = InfoCliente[0]['Id'];

    //Se reliza la consulta de la informacion del pedido
    this.serviciosService.getInfoPedido(this.IdPedido)
    .subscribe((data:[])=>{
      this.InfoPedido = data;

      //Se esablecen los campos a mostrar
      this.SubTotal = this.InfoPedido[0]["SubTotal"];
      this.Iva = this.InfoPedido[0]["Iva"];
      this.Ahorro = this.InfoPedido[0]["Ahorro"];
      this.Total = this.InfoPedido[0]["Total"];
    });
  }

  //-------------------------------------------------------------
  //Funcion para el inicio Pago
  //-------------------------------------------------------------
  InicioPago()
  {

    //Se verifica si esta logueado Y se valida que esten llenos los campos
    if(this.Email == "" || this.Identificacion == null || this.TipoDocumento =="-1" || this.Nombres =="" || this.Apellidos =="" || this.Telefono == 0)
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios, por favor verifique.',
        icon: 'warning'
      });
      return;
    }

    //------------------------------------------------------------------------
    //Validaciones de campos
    //------------------------------------------------------------------------
    //Se valida el Email
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email)){
    } else {
      Swal.fire({
        title: 'Alerta',
        text: 'La dirección de correo electrónico es inválida.',
        icon: 'warning'
      });
      return;
    }

    // Se activa el spinner
    this.spinnerActivo();
    // Se crean las variables para el manejo del token
    var RespuestaToken = [];
    var Verifica:string = "";
    var Token:string = "";
    //Se realiza la consulta del token
    this.serviciosService.getConsultaToken()
    .subscribe((data:[])=>{
      // Se captura la respuesta
      RespuestaToken = data;
      Verifica = RespuestaToken['Error'];
      Token = RespuestaToken['Token'];

      //Se verifica si se presento algun error
      if(Verifica == "0")
      {
        //Se captura la respuesta
        var DescPedido = "Pedido toolShack Numero "+ this.IdPedido;

        //Se realiza el consumo del servicio que crea el pago en la sonda
        this.serviciosService.getAlmacenarPagoSonda(Token,this.IdPedido,this.Identificacion.toString(),'40004163')
        .subscribe((data:[])=>{
          //Se captura la salida
          var RespuestaPagoSonda = [];
          RespuestaPagoSonda = data;
          var VerificaPagoSonda = RespuestaPagoSonda['Error'];

          // Se verifica si se presento algun error
          if(VerificaPagoSonda == "0")
          {
            // Variable para obtener la respuesta de la actualizacion del pago
            var RespuestaActEstadoPago = [];
            //Se actualiza el estado del pago
            this.serviciosService.getActualizaEstadoPago(this.IdPedido)
            .subscribe((data:[])=>{
              // Se Captura la respuesta y se verifica el error
              RespuestaActEstadoPago = data;
              var VerificaActEstadoPago = RespuestaActEstadoPago['Error'];
              //Se verifica si se presento algun error
              if(VerificaActEstadoPago =="0")
              {
                //Se realiza el llamado a zonaPagos
                this.serviciosService.InicioPagoZV(this.Total,this.Iva,this.IdPedido,DescPedido,this.Email,this.Identificacion.toString(),this.TipoDocumento,this.Nombres,this.Apellidos,this.Telefono)
                .subscribe((data:[])=>{
                  var InicioPagos = [];
                  var CodigoInicio = [];
                  InicioPagos = data;
                  CodigoInicio = InicioPagos["soap:Envelope"]["soap:Body"][0]["inicio_pagoV2Response"][0]["inicio_pagoV2Result"][0];
                  this.spinnerInactivo();
                  // Se limpia el pedido
                  localStorage.removeItem("Pedido");
                  //Productivo
                  window.open("https://www.zonapagos.com/t_Marpicopas/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio);
                  //Calidad
                  //window.open("https://www.zonapagos.com/t_Marpicosa2/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio);
                  this.router.navigate(['Tracking']);
                });
              }
              else{
                alert("Error al actualizar el pago, intente mas tarde.");
                this.spinnerInactivo();
              }
            });
          }
          else{
            alert("Error al almacenar datos pago");
            this.spinnerInactivo();
          }
        });
      }
    });
  }

  //------------------------------------------------------------
  // Función para activar el spinner
  //------------------------------------------------------------
  spinnerActivo(): void {
    this.spinnerService.show();
  }

  //-----------------------------------------------------------
  // Función para inactivar el spinner
  //-----------------------------------------------------------
  spinnerInactivo(): void {
    this.spinnerService.hide();
  }
}
