import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuSuperiorComponent } from './menu-superior/menu-superior.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ProductosCatalogoComponent } from './productos-catalogo/productos-catalogo.component';
import { ProductosSeccionComponent } from './productos-seccion/productos-seccion.component';
import { ProductosCategoriaComponent } from './productos-categoria/productos-categoria.component';
import { ProductoDetalleComponent } from './producto-detalle/producto-detalle.component';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ProductosBusquedaComponent } from './productos-busqueda/productos-busqueda.component';
import { ProcesoCompraComponent } from './proceso-compra/proceso-compra.component';
import { NgxSpinnerModule} from 'ngx-spinner';
import { LoginComponent } from './login/login.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { TrackingComponent } from './tracking/tracking.component';
import { CambioClaveComponent } from './cambio-clave/cambio-clave.component';
import { ComprasRealizadasComponent } from './compras-realizadas/compras-realizadas.component';
import { ExtractoComponent } from './extracto/extracto.component';
import { CentrosServiciosComponent } from './centros-servicios/centros-servicios.component';
import { DetallePedidoComponent } from './detalle-pedido/detalle-pedido.component';
import { ProcesoPagoComponent } from './proceso-pago/proceso-pago.component';
import { RegistroComponent } from './registro/registro.component';
import { LegalComponent } from './legal/legal.component';
import { DevolucionesComponent } from './devoluciones/devoluciones.component';
import { DevolucionDetalleComponent } from './devolucion-detalle/devolucion-detalle.component';
import { ListaDevolucionesComponent } from './lista-devoluciones/lista-devoluciones.component';
import { CrearDevolucionComponent } from './crear-devolucion/crear-devolucion.component';
import { ListaBlogsComponent } from './lista-blogs/lista-blogs.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { RecordarClaveComponent } from './recordar-clave/recordar-clave.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuSuperiorComponent,
    HomeComponent,
    FooterComponent,
    ProductosCatalogoComponent,
    ProductosSeccionComponent,
    ProductosCategoriaComponent,
    ProductoDetalleComponent,
    ProductosBusquedaComponent,
    ProcesoCompraComponent,
    LoginComponent,
    MiCuentaComponent,
    TrackingComponent,
    CambioClaveComponent,
    ComprasRealizadasComponent,
    ExtractoComponent,
    CentrosServiciosComponent,
    DetallePedidoComponent,
    ProcesoPagoComponent,
    RegistroComponent,
    LegalComponent,
    DevolucionesComponent,
    DevolucionDetalleComponent,
    ListaDevolucionesComponent,
    CrearDevolucionComponent,
    ListaBlogsComponent,
    DetalleBlogComponent,
    RecordarClaveComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
