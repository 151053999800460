import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';

@Component({
  selector: 'app-productos-catalogo',
  templateUrl: './productos-catalogo.component.html',
  styleUrls: ['./productos-catalogo.component.css']
})
export class ProductosCatalogoComponent implements OnInit {

  //Se crean las variables globales
  IdCatalogo:string;
  InfoCatalogo = [];
  ListaSecciones = [];
  ListaMarcas = [];
  ListaProductos = [];
  DescripcionCatalogo:string;
  ListaFiltros = [];
  DescuentoCliente:number = 0;
  Orden:number = 0;
  p:number = 1;

  // Se asignas las dependencias a usar
  constructor(private serviciosService:ServiciosService, private activatedRoute:ActivatedRoute,private router:Router) { }

  // funcion de inicio de la pagina
  ngOnInit() {

    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
    window.scroll(0,0);
      //Se captura el Id del Catalogo
      this.IdCatalogo = params.get('Id');

        //Se realiza la consulta de la información del catalogo
        this.serviciosService.getInfoCatologo(this.IdCatalogo)
        .subscribe((data:[])=>{
          this.InfoCatalogo = data;
    
          // Se estable la descripcion del catalogo
          this.DescripcionCatalogo = this.InfoCatalogo[0]['Descripcion'];
        });
    
        //Se realiza la consulta de las secciones del catalogo
        this.serviciosService.getSeccionesCatalogo(this.IdCatalogo)
        .subscribe((data:[])=>{
          this.ListaSecciones = data;
        });
    
        //Se realiza la consulta de los totales por marca
        this.serviciosService.getMarcasProductosCatalogo(this.IdCatalogo)
        .subscribe((data:[])=>{
          this.ListaMarcas = data;
        });
    
        //Se realiza la consulta de los productos
        this.serviciosService.getProductosCatalogo(this.IdCatalogo,this.Orden)
        .subscribe((data:[])=>{
          this.ListaProductos = data;
        });
    
        // Se captura la informacion del local storage
        var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
        this.DescuentoCliente = InfoCliente[0]['Descuento']/100;

    });
  }

  //-------------------------------------------------------------
  //Funcion para agregar filtros
  //-------------------------------------------------------------
  AgregarFiltro(Id:string,Descripcion:string,Tipo:string){
    // Se verifica si el registro ya existe en el filtro
    var Verifica = this.ListaFiltros.find( Registro => Registro.Nombre == Descripcion );

    //Si el registro no existe se agrega al array
    if(Verifica == undefined)
    {
      // Se agrega el regitro
      this.ListaFiltros.push({Id:Id,Nombre:Descripcion,Tipo:Tipo});
      
      //Se crean las variables
      var Secciones = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'Seccion')
        {
          if(Secciones == "")
          {
            Secciones = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Secciones = Secciones +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      //se va a la primera pagina
      this.p = 1;
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosCatalogo(this.IdCatalogo,Secciones,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }

  //------------------------------------------------------------------------------------
  //Funcion para remover filtros
  //------------------------------------------------------------------------------------
  RemoverFiltro(Id:string)
  {
    //Se remueve el filtro seleccionado
    this.ListaFiltros = this.ListaFiltros.filter(function(ListaFiltros){
      return ListaFiltros.Id !==Id;
    });

    //Se crean las variables del servicio
    var Secciones = "";
    var Marcas = "";

    // Se recorren los registros del array
    for(var Registro in this.ListaFiltros)
    {
      //Se capturan los registros
      var TipoFiltro = this.ListaFiltros[Registro].Tipo;

      //Se verifica el tipo para agregarlo a la consulta
      if(TipoFiltro == 'Seccion')
      {
        if(Secciones == "")
        {
          Secciones = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Secciones = Secciones +','+ this.ListaFiltros[Registro].Id;
        }
      }
      if(TipoFiltro == 'Marca')
      {
        if(Marcas == "")
        {
          Marcas = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
        }
      }
    }
    //Se va a la primera pagina
    this.p = 1;

    //Se realiza la busqueda con el filtro definido
    this.serviciosService.getFiltroProductosCatalogo(this.IdCatalogo,Secciones,Marcas,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

  //----------------------------------------------------------------
  //Funcion para borrar todos los filtros
  //----------------------------------------------------------------
  BorrarFiltros()
  {
    //Se limpian todos los filtros agregados
    this.ListaFiltros = [];
    //Se va a la primera pagina
    this.p = 1;
    //Se realiza la consulta de los productos
    this.serviciosService.getProductosCatalogo(this.IdCatalogo,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

  //----------------------------------------------------------------
  //Funcion para ordenar los registros
  //----------------------------------------------------------------
  Ordenamiento()
  {
    //Se verifica si hay filtros
    var NumFiltros = this.ListaFiltros.length;
    //Se va a la primera pagina
    this.p = 1;
    
    if(NumFiltros == 0)
    {
      //Se realiza la consulta de los productos
      this.serviciosService.getProductosCatalogo(this.IdCatalogo,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
    else{
      //Se crean las variables del servicio
      var Secciones = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'Seccion')
        {
          if(Secciones == "")
          {
            Secciones = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Secciones = Secciones +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosCatalogo(this.IdCatalogo,Secciones,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }
}
