import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd, ParamMap} from '@angular/router';
import { MenuSuperiorComponent} from '../menu-superior/menu-superior.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.css']
})
export class ProductoDetalleComponent implements OnInit {

  // Se realiza la creacion de las variables globales
  CodigoProducto:string = "";
  InfoProducto = [];
  Descripcion:string = "";
  Precio:number = 0;
  Imagen:string = "";
  IdCatalogo:string = "";
  IdSeccion:string = "";
  IdCategoria:string = "";
  IdSubCategoria:string = "";
  Catalogo:string = "";
  Seccion:string = "";
  Categoria:string = "";
  SubCategoria:String = "";
  Referencia:string = "";
  Empaque:string = "";
  Unidad:string = "";
  CodigoPadre:string = "";
  ListaReferencias = [];
  CantidadReferencias:number= 0;
  SelectReferencia:string = "";
  ListaRelacionados = [];
  InfoInventario = [];
  CantInventario:number = 0;
  Cantidad:number = 1;
  ListaEscalasProducto = [];
  NumEscalas:number=0;
  Nuevo:number=0;
  Items:number = 0;
  Iva:number=0;
  DescuentoCliente:number = 0;
  DescuentoClienteV:number = 0;
  InfoColores = [];
  NumColores:number = 0;
  Certificado:string = "";
  FichaTecnica:string = "";
  Detalles:string = "";
  Caracteristicas:string = "";

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute, private router:Router) {
   }

  // Se crea la funcion inicial 
  ngOnInit() {
    
    // Se captura la informacion del codigo del producto
    this.CodigoProducto = this.activatedRoute.snapshot.paramMap.get('Id');
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      this.CodigoProducto = params.get('Id');
      // Ir a la parte superior de la pagina
      window.scroll(0,0);
      // Se realiza la consulta de la base de datos
      this.serviciosService.getInfoProducto(this.CodigoProducto)
      .subscribe((data:[])=>{
        // Se captura la informacion del servicio
        this.InfoProducto = data;

        //Se extrae la información 
        this.Descripcion = this.InfoProducto[0]['Descripcion'];
        this.Precio = this.InfoProducto[0]['Precio'];
        this.Imagen = this.InfoProducto[0]['Imagen'];
        this.IdCatalogo = this.InfoProducto[0]['IdCatalogo'];
        this.IdSeccion = this.InfoProducto[0]['IdSeccion'];
        this.IdCategoria = this.InfoProducto[0]['IdCategoria'];
        this.IdSubCategoria = this.InfoProducto[0]['IdSubCategoria'];
        this.Catalogo = this.InfoProducto[0]['DescCatalogo'];
        this.Seccion = this.InfoProducto[0]['DescSeccion'];
        this.Categoria = this.InfoProducto[0]['DescCategoria'];
        this.SubCategoria = this.InfoProducto[0]['DescSubCategoria'];
        this.Referencia = this.InfoProducto[0]['Referencia'];
        this.Empaque = this.InfoProducto[0]['Empaque'];
        this.Unidad = this.InfoProducto[0]['Unidad'];
        this.CodigoPadre = this.InfoProducto[0]['CodigoPadre'];
        this.Nuevo = this.InfoProducto[0]['Nuevo'];
        this.Iva = this.InfoProducto[0]['Iva']/100;
        this.Certificado = this.InfoProducto[0]['Certificado'];
        this.FichaTecnica = this.InfoProducto[0]['FichaTecnica'];
        this.Caracteristicas = this.InfoProducto[0]['Caracteristicas'];
        this.Detalles = this.InfoProducto[0]['Detalles'];
        this.SelectReferencia = this.CodigoProducto;

        //Se realiza la consulta de las referencias
        this.serviciosService.getRefecenciasProducto(this.CodigoPadre)
        .subscribe((data:[])=>{
          this.ListaReferencias = data;
          this.CantidadReferencias = this.ListaReferencias.length;
        });
        //Se consultan los productos relacionados
        this.serviciosService.getProductosCategoria(this.IdCategoria,0)
        .subscribe((data:[])=>{
          this.ListaRelacionados = data;
        });
          // Se realiza la consulta del inventario
          this.serviciosService.getInventarioProducto(this.CodigoProducto)
          .subscribe((data:[])=>{
            this.InfoInventario = data;
            this.CantInventario = this.InfoInventario[0]['Cantidad'];
          });

          //Se realiza la consulta de las escalas del producto
          this.serviciosService.getEscalasProducto(this.CodigoProducto)
          .subscribe((data:[])=>{
            this.ListaEscalasProducto = data;
            this.NumEscalas = this.ListaEscalasProducto.length;
          });

          //se realiza la consulta de los colores del material
          this.serviciosService.getColoresMaterial(this.Descripcion)
          .subscribe((data:[])=>{
            this.InfoColores = data;
            this.NumColores = this.InfoColores.length;
          });
      });

      // Se captura la informacion del local storage
      var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
      this.DescuentoClienteV =InfoCliente[0]['Descuento'];
    });

    //Se verifica si hay registro en el LocalStorage
    if(JSON.parse(localStorage.getItem("Pedido")) == null)
    {
      this.Items = 0;
    }
    else
    {
      var Pedido = [];
      Pedido = JSON.parse(localStorage.getItem("Pedido"))
      this.Items = Pedido.length;
    }
  }

  //-----------------------------------------------------------
  // Funcion para cuando se cambia la referencia
  //-----------------------------------------------------------
  CambioReferencia()
  {
    this.router.navigate(['ProductoDetalle/'+this.SelectReferencia]);
  }

  //-----------------------------------------------------------
  // Funcion para cuando se cambia la referencia
  //-----------------------------------------------------------
  CambiarColor(IdCodigo:string)
  {
    this.router.navigate(['ProductoDetalle/'+IdCodigo]);
  }
  //-----------------------------------------------------------
  //Aumentar Cantidad
  //-----------------------------------------------------------
  AumentarCantidad()
  {
    if(this.Cantidad < this.CantInventario)
      {
        this.Cantidad = this.Cantidad + 1;
      }
  }
  //-----------------------------------------------------------
  //Aumentar Cantidad
  //-----------------------------------------------------------
  DisminuirCantidad()
  {
    // Se verifica que no sea 0 para restar 1
    if(this.Cantidad > 0)
    {
      this.Cantidad = this.Cantidad - 1;
    }
  }

  //-----------------------------------------------------------
  //Agregar al carrito
  //-----------------------------------------------------------
  AgregarCarrito()
  {
    if(this.Cantidad > this.CantInventario)
    {
      Swal.fire({
        title: 'Alerta',
        text:'No hay stock suficiente de este material.',
        icon:'warning'
      });
      return;
    }
    // Se verifica que no sea 0 para restar 1
    if(this.Cantidad > 0)
    {
      var Pedido = [];
      if(JSON.parse(localStorage.getItem("Pedido")) == null)
      {
        var ValEscala:number = 0;
        //Se agrega la informacion del producto al local
        if(this.NumEscalas > 0)
        {
          //Se crea la variable del descuento de la escala
          var ValEscala:number = 0;
          //Se recorren las escalas del producto
          for(var Registro in this.ListaEscalasProducto)
          {
            //Se captura la cantidad de la escala
            var CantEscala = this.ListaEscalasProducto[Registro].Cantidad;
            if(this.Cantidad >= CantEscala)
            {
              ValEscala = this.ListaEscalasProducto[Registro].Descuento;
            }
          }
        }
        Pedido.push({Codigo:this.CodigoProducto,Cantidad:this.Cantidad,Precio:this.Precio,"Descripcion": this.Descripcion,Empaque:this.Empaque,Imagen:this.Imagen,SubTotal:this.Precio*this.Cantidad,Referencia:this.Referencia,Iva:this.Iva,DesEscala:ValEscala,"Unidad":this.Unidad});
        localStorage.setItem("Pedido",JSON.stringify(Pedido));
        this.Items = Pedido.length;

        Swal.fire({
          text:'Producto añadido al carrito.',
          icon:'success'
        });
      }
      else
      {
        //Se carga la informacion del pedido
        Pedido = JSON.parse(localStorage.getItem("Pedido"));

        // Se verifica si el registro ya existe en el filtro
        var Verifica = Pedido.find( Registro => Registro.Codigo == this.CodigoProducto );

        //Si no existe el producto en el array
        if(Verifica == undefined)
        {
          var ValEscala:number = 0;
          //Se agrega la informacion del producto al local
          if(this.NumEscalas > 0)
          {
            //Se crea la variable del descuento de la escala
            var ValEscala:number = 0;
            //Se recorren las escalas del producto
            for(var Registro in this.ListaEscalasProducto)
            {
              //Se captura la cantidad de la escala
              var CantEscala = this.ListaEscalasProducto[Registro].Cantidad;
              if(this.Cantidad > CantEscala)
              {
                ValEscala = this.ListaEscalasProducto[Registro].Descuento;
              }
            }
          }
          // Se agrega la informacion de producto al pedido
          Pedido.push({Codigo:this.CodigoProducto,Cantidad:this.Cantidad,Precio:this.Precio,"Descripcion": this.Descripcion,Empaque:this.Empaque,Imagen:this.Imagen,SubTotal:this.Precio*this.Cantidad,Referencia:this.Referencia,Iva:this.Iva,DesEscala:ValEscala,"Unidad":this.Unidad});
          localStorage.removeItem("Pedido");
          localStorage.setItem("Pedido",JSON.stringify(Pedido));
          this.Items = Pedido.length;

          Swal.fire({
            text:'Producto añadido al carrito.',
            icon:'success'
          });
        }
        else{
          Swal.fire({
            title: 'Alerta',
            text:'El producto ya se encuentra añadido, si desea modificar las cantidades puede realizarlo desde el carrito de compras.',
            icon:'warning'
          });
        }
      }
    }
  }
}
