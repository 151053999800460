import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd, ParamMap} from '@angular/router';

@Component({
  selector: 'app-productos-seccion',
  templateUrl: './productos-seccion.component.html',
  styleUrls: ['./productos-seccion.component.css']
})
export class ProductosSeccionComponent implements OnInit {

  // Se crean las variables globales
  IdSeccion:string;
  ListaProductos = [];
  ListaMarcas = [];
  ListaCategorias = [];
  InfoSeccion = [];
  DescripcionSeccion:string = "";
  ListaFiltros = [];
  DescuentoCliente:number = 0;
  Orden:number = 0;
  p:number = 1;

  constructor(private serviciosService:ServiciosService, private activatedRoute:ActivatedRoute, private router:Router) { 
  }

  // Inicio de la pagina
  ngOnInit() {
    // Se va al inicio de la pagina
    window.scroll(0,0)
    //Se verifica cambio en el parametro de la url
      this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
        // Se captura el Id de la seccion
        this.IdSeccion = params.get('Id');
          // Se realiza la consulta de la informacion de la seccion
          this.serviciosService.getInfoSeccion(this.IdSeccion)
          .subscribe((data:[])=>{
            this.InfoSeccion = data;
            this.DescripcionSeccion = this.InfoSeccion[0]['Descripcion'];
          })

          // Se realiza la consulta de los productos
          this.serviciosService.getProductosSeccion(this.IdSeccion,this.Orden)
          .subscribe((data:[])=>{
            this.ListaProductos = data;
          });

          //Se consultan las categorias de la sección
          this.serviciosService.getCategoriasSeccion(this.IdSeccion)
          .subscribe((data:[])=>{
            this.ListaCategorias = data;
          });

          //Se realiza la consulta de las marcas
          this.serviciosService.getMarcasProductosSeccion(this.IdSeccion)
          .subscribe((data:[])=>{
            this.ListaMarcas = data;
          });
      });

      // Se captura la informacion del local storage
      var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
  }

  //------------------------------------------------------------------------------------
  //Funcion para agregar filtros
  //------------------------------------------------------------------------------------
  AgregarFiltro(Id:string,Descripcion:string,Tipo:string){
    // Se verifica si el registro ya existe en el filtro
    var Verifica = this.ListaFiltros.find( Registro => Registro.Nombre == Descripcion );

    //Si el registro no existe se agrega al array
    if(Verifica == undefined)
    {
      // Se agrega el regitro
      this.ListaFiltros.push({Id:Id,Nombre:Descripcion,Tipo:Tipo});
      
      //Se crean las variables
      var Categorias = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'Categoria')
        {
          if(Categorias == "")
          {
            Categorias = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Categorias = Categorias +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }

      //Se va a la pagina 1
      this.p = 1;
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosSeccion(this.IdSeccion,Categorias,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }
  
  //------------------------------------------------------------------------------------
  //Funcion para remover filtros
  //------------------------------------------------------------------------------------
  RemoverFiltro(Id:string)
  {
    //Se remueve el filtro seleccionado
    this.ListaFiltros = this.ListaFiltros.filter(function(ListaFiltros){
      return ListaFiltros.Id !==Id;
    });

    //Se crean las variables del servicio
    var Categorias = "";
    var Marcas = "";

    // Se recorren los registros del array
    for(var Registro in this.ListaFiltros)
    {
      //Se capturan los registros
      var TipoFiltro = this.ListaFiltros[Registro].Tipo;

      //Se verifica el tipo para agregarlo a la consulta
      if(TipoFiltro == 'Categoria')
      {
        if(Categorias == "")
        {
          Categorias = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Categorias = Categorias +','+ this.ListaFiltros[Registro].Id;
        }
      }
      if(TipoFiltro == 'Marca')
      {
        if(Marcas == "")
        {
          Marcas = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
        }
      }
    }
    //Se va a la pagina 1
    this.p = 1;
    //Se realiza la busqueda con el filtro definido
    this.serviciosService.getFiltroProductosSeccion(this.IdSeccion,Categorias,Marcas,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

  //----------------------------------------------------------------
  //Funcion para borrar todos los filtros
  //----------------------------------------------------------------
  BorrarFiltros()
  {
    //Se limpian todos los filtros agregados
    this.ListaFiltros = [];
    this.p = 1;
    //Se realiza la consulta de los productos
    this.serviciosService.getProductosSeccion(this.IdSeccion,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }
  
  //----------------------------------------------------------------
  //Funcion para ordenar los registros
  //----------------------------------------------------------------
  Ordenamiento()
  {
    //Se verifica si hay filtros
    var NumFiltros = this.ListaFiltros.length;

    //Se va a la pagina 1
    this.p = 1;

    if(NumFiltros == 0)
    {
      //Se realiza la consulta de los productos
      this.serviciosService.getProductosSeccion(this.IdSeccion,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
    else{
      //Se crean las variables del servicio
      var Categorias = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'Categoria')
        {
          if(Categorias == "")
          {
            Categorias = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Categorias = Categorias +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosSeccion(this.IdSeccion,Categorias,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }
}
