import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compras-realizadas',
  templateUrl: './compras-realizadas.component.html',
  styleUrls: ['./compras-realizadas.component.css']
})
export class ComprasRealizadasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
