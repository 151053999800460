import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extracto',
  templateUrl: './extracto.component.html',
  styleUrls: ['./extracto.component.css']
})
export class ExtractoComponent implements OnInit {

  //Se crean las variables globales
  Anio:string = "-1"
  Mes:string = "-1"
  
  constructor() { }

  ngOnInit() {
  }

}
