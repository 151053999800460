import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as CryptoJS  from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(private http:HttpClient) { }


  // Se establece la Url para los servicios
  //Productivo
  URL:string = "https://api.toolshack.com.co/";
  //Calidad
  //URL:string = "http://192.168.11.16:3300/";

  //Se realiza la creacion de los servicios
  //------------------------------------------------------------------------
  // Servicio para la consulta de los catalogos
  //------------------------------------------------------------------------
  getCatalogos(){
    return this.http.get(this.URL + 'catalogos');
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta de las secciones
  //------------------------------------------------------------------------
  getSecciones()
  {
    return this.http.get(this.URL + 'secciones');
  }  

  //------------------------------------------------------------------------
  // Servicio para la consulta de las categorias
  //------------------------------------------------------------------------
  getCategorias()
  {
    return this.http.get(this.URL + 'categorias');
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta la informacion del catalogo
  //------------------------------------------------------------------------
  getInfoCatologo(Id:string)
  {
    return this.http.get(this.URL + 'infoCatalogo?Id='+Id);
  }
  //------------------------------------------------------------------------
  // Servicio para la consulta de las secciones del catalogo
  //------------------------------------------------------------------------
  getSeccionesCatalogo(Id:string)
  {
    return this.http.get(this.URL + 'seccionesCatalogo?Id='+Id);
  }
  //------------------------------------------------------------------------
  // Servicio para la consulta de los productos del catalogo
  //------------------------------------------------------------------------
  getProductosCatalogo(Id:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'productosCatalogo?Id='+Id+'&Ordenamiento='+Ordenamiento);
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta de los totales por Marca
  //------------------------------------------------------------------------
  getTotalesMarca()
  {
    return this.http.get(this.URL + 'totalesMarca');
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta de los productos de la seccion
  //------------------------------------------------------------------------
  getProductosSeccion(Id:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'productosSeccion?Id='+Id+'&Ordenamiento='+Ordenamiento);
  }
  
  //------------------------------------------------------------------------
  // Servicio para la consulta de los productos de la categoria
  //------------------------------------------------------------------------
  getProductosCategoria(Id:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'productosCategoria?Id='+Id+'&Ordenamiento='+Ordenamiento);
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de la seccion
  //------------------------------------------------------------------------
  getInfoSeccion(Id:string)
  {
    return this.http.get(this.URL + 'infoSeccion?Id='+Id);
  }

  //------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de la seccion
  //------------------------------------------------------------------------
  getInfoCategoria(Id:string)
  {
    return this.http.get(this.URL + 'infoCategoria?Id='+Id);
  }
  
  //-----------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de las categorias de la seccion
  //-----------------------------------------------------------------------------
  getCategoriasSeccion(Id:string)
  {
    return this.http.get(this.URL + 'categoriasSeccion?Id='+Id);
  }

  //---------------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de las subcategorias de la categoria
  //-----------------------------------------------------------------------------------
  getSubCategoriasCategorias(Id:string)
  {
    return this.http.get(this.URL + 'subcategoriasCategoria?Id='+Id);
  }

  //---------------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de las subcategorias de la categoria
  //-----------------------------------------------------------------------------------
  getProductosDestacados()
  {
    return this.http.get(this.URL + 'productosDestacados');
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta de la informacion de las subcategorias de la categoria
  //-----------------------------------------------------------------------------------
  getListaCategoriasSeccion(Id:string)
  {
    return this.http.get(this.URL + 'listaCategoriasSeccion?Id='+Id);
  }

  //-----------------------------------------------------------------------------------
  // Servicio para la consulta el filtro de los productos de catalogo
  //-----------------------------------------------------------------------------------
  getFiltroProductosCatalogo(IdCatalogo:string,Secciones:string,Marcas:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'filtroProductosCatalogo?IdCatalogo='+IdCatalogo+'&Secciones='+Secciones+'&Marcas='+Marcas+'&Ordenamiento='+Ordenamiento);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta el filtro de los productos de la Seccion
  //-----------------------------------------------------------------------------------
  getFiltroProductosSeccion(IdSeccion:string,Categorias:string,Marcas:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'filtroProductosSeccion?IdSeccion='+IdSeccion+'&Categorias='+Categorias+'&Marcas='+Marcas+'&Ordenamiento='+Ordenamiento);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta el filtro de los productos de la Categoria
  //-----------------------------------------------------------------------------------
  getFiltroProductosCategoria(IdCategoria:string,SubCategorias:string,Marcas:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'filtroProductosCategoria?IdCategoria='+IdCategoria+'&SubCategorias='+SubCategorias+'&Marcas='+Marcas+'&Ordenamiento='+Ordenamiento);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta el filtro de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getFiltroProductosBusqueda(CriterioBusqueda:string,SubCategorias:string,Marcas:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'filtroProductosBusqueda?Criterio='+CriterioBusqueda+'&SubCategorias='+SubCategorias+'&Marcas='+Marcas+'&Ordenamiento='+Ordenamiento);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos del catalogo
  //-----------------------------------------------------------------------------------
  getMarcasProductosCatalogo(Id:string)
  {
    return this.http.get(this.URL + 'marcasCatalogo?Id='+Id);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos del seccion
  //-----------------------------------------------------------------------------------
  getMarcasProductosSeccion(Id:string)
  {
    return this.http.get(this.URL + 'marcasSeccion?Id='+Id);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos del categoria
  //-----------------------------------------------------------------------------------
  getMarcasProductosCategoria(Id:string)
  {
    return this.http.get(this.URL + 'marcasCategoria?Id='+Id);
  }

  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos del categoria
  //-----------------------------------------------------------------------------------
  getProductosBusqueda(Criterio:string,Ordenamiento:number)
  {
    return this.http.get(this.URL + 'busquedaProductos?Criterio='+Criterio+'&Ordenamiento='+Ordenamiento);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las subcategorias de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getSubcategoriasBusqueda(Criterio:string)
  {
    return this.http.get(this.URL + 'subcategoriasBusqueda?Criterio='+Criterio);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getMarcasBusqueda(Criterio:string)
  {
    return this.http.get(this.URL + 'marcasBusqueda?Criterio='+Criterio);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getInfoProducto(Codigo:string)
  {
    return this.http.get(this.URL + 'infoProducto?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getRefecenciasProducto(Codigo:string)
  {
    return this.http.get(this.URL + 'referenciasProducto?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta las marcas de los productos de la busqueda
  //-----------------------------------------------------------------------------------
  getInventarioProducto(Codigo:string)
  {
    return this.http.get(this.URL + 'inventarioProducto?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta de las escalas del producto
  //-----------------------------------------------------------------------------------
  getEscalasProducto(Codigo:string)
  {
    return this.http.get(this.URL + 'escalasProducto?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta los descuentos del cliente
  //-----------------------------------------------------------------------------------
  getDescuentoCliente(Codigo:string)
  {
    return this.http.get(this.URL + 'descuentoCliente?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta los departamentos
  //-----------------------------------------------------------------------------------
  getDepartamentos()
  {
    return this.http.get(this.URL + 'departamentos');
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta los departamentos
  //-----------------------------------------------------------------------------------
  getCiudadesDepartamentos(Codigo:string)
  {
    return this.http.get(this.URL + 'ciudadesDepartamento?Codigo='+Codigo);
  }
  //-----------------------------------------------------------------------------------
  // Servicio para la consulta del token
  //-----------------------------------------------------------------------------------
  getConsultaToken()
  {
    return this.http.get(this.URL + 'consultaToken');
  }
  //-----------------------------------------------------------------------------------
  // Servicio para crear la cabecera del pedido
  //-----------------------------------------------------------------------------------
  CrearPedidoCabecera(IdCliente:string,
    SubTotal:string,
    Iva:string,
    Total:string,
    CodigoSap:string,
    Nombres:string,
    Apellidos:string,
    Direccion:string,
    Region:string,
    Ciudad:string,
    Telefono:string,
    Email:string,
    Identificacion:string,
    Ahorro:string)
  {
    return this.http.get(this.URL + 'crearPedidoCabecera?IdCliente='+IdCliente+'&SubTotal='+SubTotal+'&Iva='+Iva+'&Total='+Total+'&CodigoSap='+CodigoSap+'&Nombres='+Nombres+'&Apellidos='+Apellidos+'&Direccion='+Direccion+'&Region='+Region+'&Ciudad='+Ciudad+'&Telefono='+Telefono+'&Email='+Email+'&Identificacion='+Identificacion+'&Ahorro='+Ahorro);

  }

  //-----------------------------------------------------------------------------------
  // Servicio para crear los detalles del pedido
  //-----------------------------------------------------------------------------------
  CrearPedidoDetalles(Pedido:any[])
  {
    var json = JSON.stringify(Pedido);
    var params = "json="+ json;
    var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.URL + 'crearPedidoDetalles',params,{headers:headers});
  }

  //-----------------------------------------------------------------------------------
  // Servicio para el inicio pagos
  //-----------------------------------------------------------------------------------
  InicioPagoZV(Total:number,Iva:number,IdPedido:string,DescPedido:string,Email:string,Identificacion:string,TipoDocumento:string,Nombres:string,Apellidos:string,Telefono:number)
  {
    return this.http.get(this.URL + 'inicioPagos?Total='+Total+'&Iva='+Iva+'&IdPedido='+IdPedido+'&DescPedido='+DescPedido+'&Email='+Email+'&Identificacion='+Identificacion+'&TipoDocumento='+TipoDocumento+'&Nombres='+Nombres+'&Apellidos='+Apellidos+'&Telefono='+Telefono);
  }

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getAlmacenarPagoSonda(Token:string,ReferenciaPago:string,IdentificacionCliente:string,CodigoCliente:string){
    return this.http.get(this.URL + 'almacenarPagoSonda?Token='+Token+'&ReferenciaPago='+ReferenciaPago+'&IdentificacionCliente='+IdentificacionCliente+'&CodigoCliente='+CodigoCliente);
  }

  //------------------------------------------------------------------------
  // Servicio para realiar el login
  //------------------------------------------------------------------------
  getLogin(InfoLogin:any[]){
    var json = JSON.stringify(InfoLogin);
    var params = "json="+ json;
    var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.URL + 'login?',params,{headers:headers});
  }
  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getinfoUsuario(Id:string){
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders().set('access-token', Token);
    return this.http.get(this.URL + 'infoUsuario?Id='+Id,{headers:headers});
  }

  //------------------------------------------------------------------------
  // Servicio para consultar las direcciones
  //------------------------------------------------------------------------
  getDirecciones(Id:string){
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders().set('access-token', Token);
    return this.http.get(this.URL + 'direcciones?IdUsuario='+Id,{headers:headers});
  }
  
  //------------------------------------------------------------------------
  // Servicio para consultar las direcciones
  //------------------------------------------------------------------------
  getListaPedidos(Id:string){
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders().set('access-token', Token);
    return this.http.get(this.URL + 'listaPedidos?IdUsuario='+Id,{headers:headers});
  }

  //------------------------------------------------------------------------
  // Servicio para realizar el cambio de clave
  //------------------------------------------------------------------------
  getCambiarClave(Clave:any[]){
    var json = JSON.stringify(Clave);
    var params = "json="+ json;
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders({'access-token':Token,'Content-Type':'application/x-www-form-urlencoded'});
    return this.http.post(this.URL + 'cambiarClave?',params,{headers:headers});
  }

  //------------------------------------------------------------------------
  // Servicio para realizar el cambio de clave
  //------------------------------------------------------------------------
  getActualizarDatos(IdUsuario:string,Identificacion:string,Nombres:string,Apellidos:string,Telefono:string,Celular:string,Email:string,Departamento:string,Ciudad:string,TipoDocumento:string){
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders().set('access-token', Token);
    return this.http.get(this.URL + 'actualizarDatos?IdUsuario='+IdUsuario+'&Identificacion='+Identificacion+'&Nombres='+Nombres+'&Apellidos='+Apellidos+'&Telefono='+Telefono+'&Celular='+Celular+'&Email='+Email+'&Departamento='+Departamento+'&Ciudad='+Ciudad+'&TipoDocumento='+TipoDocumento,{headers:headers});
  }
  
  //------------------------------------------------------------------------
  // Servicio para realizar el cambio de clave
  //------------------------------------------------------------------------
  getAregarDireccion(InfoDireccion:any[]){
    var json = JSON.stringify(InfoDireccion);
    var params = "json="+ json;
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders({'access-token':Token,'Content-Type':'application/x-www-form-urlencoded'});
    return this.http.post(this.URL + 'agregarDireccion?',params,{headers:headers});
  }

  //------------------------------------------------------------------------
  // Servicio para consultar el detalle de un pedido
  //------------------------------------------------------------------------
  getDetallePedido(IdPedido:string){
    return this.http.get(this.URL + 'detallePedido?IdPedido='+IdPedido);
  }

  //------------------------------------------------------------------------
  // Servicio para consultar la informacion de cabecera de un pedido
  //------------------------------------------------------------------------
  getInfoPedido(IdPedido:string){
    return this.http.get(this.URL + 'infoPedido?IdPedido='+IdPedido);
  }

  //------------------------------------------------------------------------
  // Servicio para actualizar el estado del pedido
  //------------------------------------------------------------------------
  getActualizaEstadoPago(IdPedido:string){
    return this.http.get(this.URL + 'actualizaEstadoPedido?IdPedido='+IdPedido);
  }

  //------------------------------------------------------------------------
  // Servicio para realizar el registro de un usuario
  //------------------------------------------------------------------------
  getRegistro(InfoRegistro:any[]){
    var json = JSON.stringify(InfoRegistro);
    var params = "json="+ json;
    var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.URL + 'Registro?',params,{headers:headers});
  }
  //------------------------------------------------------------------------
  // Servicio para verificar si ya existe el usuario
  //------------------------------------------------------------------------
  getVerificaUsuario(Correo:string){
      return this.http.get(this.URL + 'verificaUsuario?Correo='+Correo);
  }
  //------------------------------------------------------------------------
  // Servicio para verificar si ya existe el usuario
  //------------------------------------------------------------------------
  getRecordarClave(Correo:string){
    return this.http.get(this.URL + 'recordarClave?Correo='+Correo);
  }
  //------------------------------------------------------------------------
  // Servicio para consultar los colores de un material
  //------------------------------------------------------------------------
  getColoresMaterial(Descripcion:string){
    return this.http.get(this.URL + 'coloresMaterial?Descripcion='+Descripcion);
  }
  //------------------------------------------------------------------------
  // Servicio para consultar los colores de un material
  //------------------------------------------------------------------------
  getActualizaDireccion(InfoDireccion:any[]){
    var json = JSON.stringify(InfoDireccion);
    var params = "json="+ json;
    var Token = CryptoJS.AES.decrypt(localStorage.getItem("Token").trim(),"Marpico2021*").toString(CryptoJS.enc.Utf8);
    var headers = new HttpHeaders({'access-token':Token,'Content-Type':'application/x-www-form-urlencoded'});
    return this.http.post(this.URL + 'actualizaDireccion?',params,{headers:headers});
    }

  //------------------------------------------------------------------------
  // Servicio para consultar la lista legal
  //------------------------------------------------------------------------
  getListaLegal(){
    return this.http.get(this.URL + 'listaLegal');
  }

  //------------------------------------------------------------------------
  // Servicio para consultar la informacion de una opción legal
  //------------------------------------------------------------------------
  getInfoLegal(Id:string){
    return this.http.get(this.URL + 'consultaInfoLegal?Id='+Id);
  }
  //------------------------------------------------------------------------
  // Servicio para consultar la informacion de una opción legal
  //------------------------------------------------------------------------
  getListaBlogs(){
    return this.http.get(this.URL + 'listaBlogs');
  }
   //------------------------------------------------------------------------
  // Servicio para consultar la informacion de una opción legal
  //------------------------------------------------------------------------
  getDetalleBlog(IdBlog:string){
    return this.http.get(this.URL + 'detalleBlog?Id='+IdBlog);
  }
  //------------------------------------------------------------------------
  // Servicio para guardar las busquedas
  //------------------------------------------------------------------------
  GuardarBusqueda(Busqueda:any[])
  {
    var json = JSON.stringify(Busqueda);
    var params = "json="+ json;
    var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.URL + 'guardarBusqueda',params,{headers:headers});
  }
  //------------------------------------------------------------------------
  // Servicio para realizar el cambio de clave
  //------------------------------------------------------------------------
  RestablecerClave(Clave:any[] ){
    var json = JSON.stringify(Clave);
    var params = "json="+ json;
    var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.URL + 'restablecerClave?',params,{headers:headers});
  }
  
}
