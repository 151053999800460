import { Component, OnInit } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  //Variables globales 
  ListaCatalogos = [];
  ListaDestacados = [];
  ListaCategorias = [];
  DescuentoCliente:number = 0;
  Login:number;

  constructor(private serviciosService:ServiciosService) { }

  ngOnInit() {

    //Se va a la parte superior de la pantalla
    window.scroll(0,0);
    
    //Se realiza la consulta de los catalogos
    this.serviciosService.getCatalogos()
    .subscribe((data:[])=>{
      this.ListaCatalogos = data;
    });

    //Se realiza la consulta de los productos destacados
    this.serviciosService.getProductosDestacados()
    .subscribe((data:[])=>{
      this.ListaDestacados = data;
    });

    //Se realiza la consulta de las categorias de Maverick
    this.serviciosService.getListaCategoriasSeccion('9')
    .subscribe((data:[])=>{
      this.ListaCategorias = data;
    });

    //Se verifica si ya se tienen las condiciones del cliente
    if(JSON.parse(localStorage.getItem("InfoCliente")) == null)
    {
      //Se realiza la consulta de los descuentos del cliente
      //Productivo
      this.serviciosService.getDescuentoCliente("200000023")
      //Calidad
      //this.serviciosService.getDescuentoCliente("200000015")
      .subscribe((data:[])=>{
        localStorage.setItem("InfoCliente",JSON.stringify(data));
        // Se captura la informacion del local storage
        var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
        this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
        this.Login = InfoCliente[0]['Login'];
      });
    }
    else{

      //Se realiza la consulta de los descuentos del cliente
      //Productivo
      this.serviciosService.getDescuentoCliente("200000023")
      //Calidad
      //this.serviciosService.getDescuentoCliente("200000015")
      .subscribe((data:[])=>{

        // Se captura la informacion del local storage
        var InfoClienteNuevo =[];
        var InfoClienteActual = [];
        InfoClienteNuevo = data;  
        //Se valida si el servicio encontro respuesta
        if(InfoClienteNuevo.length > 0)
        {
          InfoClienteActual = JSON.parse(localStorage.getItem("InfoCliente"));
          var Descuento = InfoClienteNuevo[0]['Descuento'];
  
          //Se capturan las variables de la sesion
          this.DescuentoCliente = InfoClienteNuevo[0]['Descuento']/100;
          this.Login = InfoClienteActual[0]['Login'];
          var CodigoSap = InfoClienteNuevo[0]['CodigoSap'];
  
          //Se remueve la información del cliente
          localStorage.removeItem("InfoCliente");
  
          //Se ajuste el Item que cambio
          InfoClienteActual.map(function(Registro){
            if(Registro.CodigoSap == CodigoSap){
              Registro.Descuento = Descuento;
            }
          });  

          //Se agrega la nueva informacion del cliente
          localStorage.setItem("InfoCliente",JSON.stringify(InfoClienteActual));
        }
        else{
          var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
          this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
          this.Login = InfoCliente[0]['Login'];
        }   
      });
    }
  }

}
