import { Component, OnInit, Input } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import Swal from 'sweetalert2';
import * as CryptoJS  from 'crypto-js';

@Component({
  selector: 'app-menu-superior',
  templateUrl: './menu-superior.component.html',
  styleUrls: ['./menu-superior.component.css']
})
export class MenuSuperiorComponent implements OnInit {

  //Se declaran las variables generales
  ListaCatalogos = [];
  ListaSecciones = [];
  ListaCategorias = [];
  Usuario:string = "";
  Clave:string = "";
  CriterioBusqueda:string = "";
  InfoUsuario = [];
  NombreUsuario:string="";
  LoginP:boolean=false;
  @Input() Items:number = 0;

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute, private router:Router,private location: Location) { }

  // Inicio de la pagina
  ngOnInit() {
    //-----------------------------------------------------------------------
    // Se realiza el llamado del servicio que consulta los catalogos
    this.serviciosService.getCatalogos()
    .subscribe((data:[])=>{
      //Se carga la informacion de los catalogos
      this.ListaCatalogos = data;

      //----------------------------------------------------------------------
      //Se realiza la consulta de las secciones
      this.serviciosService.getSecciones()
      .subscribe((data:[])=>{
        this.ListaSecciones = data;

        //-------------------------------------------------------------------
        // Se realiza la consulta de las categorias
        //-------------------------------------------------------------------
        this.serviciosService.getCategorias()
        .subscribe((data:[])=>{

          // Se llena la informacion de las categorias
          this.ListaCategorias = data;
        });
      });
    });

    //Se verifica si hay registro en el LocalStorage
    if(JSON.parse(localStorage.getItem("Pedido")) == null)
    {
      this.Items = 0;
    }
    else
    {
      var Pedido = [];
      Pedido = JSON.parse(localStorage.getItem("Pedido"))
      this.Items = Pedido.length;
    }

    //Se verifica si esta logueado o no
    if(JSON.parse(localStorage.getItem("InfoCliente")) == null)
    {
      this.LoginP = false;
    }
    else{
      // Se captura la informacion del cliente
      var InfoCliente = [];
      InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      var Login = InfoCliente[0]["Login"];
      //Se verifica la variable
      if(Login == 0)
      {
        this.LoginP = false;
      }
      else{
        this.NombreUsuario = InfoCliente[0]["Nombres"] +" "+ InfoCliente[0]["Apellidos"];;
        this.LoginP = true;
      }
    }
  }

  //---------------------------------------------------------------------------
  // Se realiza la ejecucion de la busqueda
  //---------------------------------------------------------------------------
  Busqueda()
  {
    //Se verifica que el campo tenga informacion
    if(this.CriterioBusqueda == "")
    {
      Swal.fire({
        title: 'Alerta',
        text:'No existe ningun criterio de busqueda, por favor verifique.',
        icon:'warning'
      });
    }
    else
    {
      //Se crea el objeto con la creacion de la busqueda
      var Busqueda = [];
      Busqueda.push({"CodigoSap":"200000023","Criterio":this.CriterioBusqueda});

      //se almacena la informacion de la busqueda en la base de datos
      this.serviciosService.GuardarBusqueda(Busqueda)
      .subscribe((data:[])=>{
      });
      this.router.navigate(['ProductosBusqueda/'+this.CriterioBusqueda]);
      //Se limpia el campo
      this.CriterioBusqueda = "";
    }
  }

  //---------------------------------------------------------------------------
  // Se realiza el loguin
  //---------------------------------------------------------------------------
  Login()
  {
    //Se verifica que los dos campos este llenos
    if(this.Usuario !="" && this.Clave !="")
    {
      //Se crean las variables para verificar si existe el usuario
      var VerificaUsuario = [];
      var VerificaRespuesta:string = "";  

      // Se captura la informacion del local storage
      var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      var DescuentoCliente = InfoCliente[0]['Descuento'];

      // Se verifica si el usuario esta registrado
      this.serviciosService.getVerificaUsuario(this.Usuario)
      .subscribe((data:[])=>{
        VerificaUsuario = data;
        VerificaRespuesta = VerificaUsuario[0]['Verifica'];
        if(VerificaRespuesta=="0")
        {
          Swal.fire({
            title: 'Alerta',
            text:'El usuario no se encuentra registrado en nuestro portal.',
            icon:'warning'
          });
        }
        else{
          var InfoLogin = [];
          InfoLogin.push({"Usuario":this.Usuario,"Clave":this.Clave})
          //Se llama el servicio de login
          this.serviciosService.getLogin(InfoLogin)
          .subscribe((data:[])=>{
            this.InfoUsuario = data;
            //Se verifica si hay registros
            var Verifica:number = this.InfoUsuario.length;

            //Se veririfica la respuesta
            if(Verifica > 0){
              var url= window.location.hash;
              // Se limpia la info del cliente
              var InfoCliente = [{"Id":this.InfoUsuario[0]["IdUsuario"],"CodigoSap":this.InfoUsuario[0]["CodigoSap"],"Descuento":DescuentoCliente,"Nombres":this.InfoUsuario[0]["Nombres"],"Apellidos":this.InfoUsuario[0]["Apellidos"],"Identificacion":this.InfoUsuario[0]["Identificacion"],"Celular":this.InfoUsuario[0]["Celular"],"Login":1,"Correo":this.InfoUsuario[0]["Correo"]}]
              localStorage.removeItem("InfoCliente");
              localStorage.setItem("InfoCliente",JSON.stringify(InfoCliente));
              //Se actualiza el token
              localStorage.removeItem("Token");
              localStorage.setItem("Token",CryptoJS.AES.encrypt(this.InfoUsuario[0]["Token"].trim(),"Marpico2021*").toString());
              this.LoginP = true;
              this.router.navigate(['home']);

              //Se verifica la ruta para actualizar.
              if(url =="#/home")
              {
                window.location.reload();
              }
            }
            else{
              Swal.fire({
                title: 'Alerta',
                text:'Credenciales incorrectas por favor verifique.',
                icon:'warning'
              });
            }
          });
        }
      });
    }
    else{
      Swal.fire({
        title: 'Alerta',
        text:'Existen Campos Vacios por favor verifique.',
        icon:'warning'
      });
    }
  }

  //---------------------------------------------------------------------------
  // Se realiza el cierre de sesión
  //---------------------------------------------------------------------------
  CerrarSesion()
  {
    var url= window.location.hash;
    localStorage.removeItem("InfoCliente");
    this.router.navigate(['home']);

    //Se verifica la ruta para actualizar.
    if(url =="#/home")
    {
      window.location.reload();
    }
  }

}
