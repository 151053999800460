import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambio-clave',
  templateUrl: './cambio-clave.component.html',
  styleUrls: ['./cambio-clave.component.css']
})
export class CambioClaveComponent implements OnInit {

  //Se realiza la captura de las variables globales
  ClaveActual:string="";
  ClaveNueva:string="";
  ClaveNueva2:string ="";

  constructor(private serviciosService:ServiciosService) { }

  //Funcion de inicio de la pagina
  ngOnInit() {
  }

  //--------------------------------------------------
  //Funcion para el cambio de contraseña
  //--------------------------------------------------
  CambioClave()
  {
    //Se captura el Id del local storage
    var InfoCliente = [];
    var InfoUsuario =[];
    InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var Id = InfoCliente[0]["Id"];

    //Se verifican que los campos esten llenos
    if(this.ClaveActual != "" && this.ClaveNueva != "" && this.ClaveNueva2 !="")
    {
      if(this.ClaveNueva != this.ClaveNueva2)
      {
        Swal.fire({
          title: 'Alerta',
          text:'La clave nueva y la confirmación no coinciden, por favor verifique.',
          icon:'warning'
        });
        return
      }
      var AClave = [];
      AClave.push({"IdUsuario":Id,"Clave":this.ClaveNueva,"ClaveActual":this.ClaveActual});
      //Se consulta el servicio cambio de clave
      this.serviciosService.getCambiarClave(AClave)
      .subscribe((data:[])=>{
        var RtaCambioClave = [];
        RtaCambioClave = data;
        var VerificaCambio = RtaCambioClave["Error"];
        //Se verifica si se presentaron errores
        if(VerificaCambio =="0")
        {
          Swal.fire({
            text: 'Cambio de clave exitoso.',
            icon: 'success'
          });
          this.ClaveActual ="";
          this.ClaveNueva ="";
          this.ClaveNueva2 ="";
        }else if(VerificaCambio =="1"){
          Swal.fire({
            title: 'Alerta',
            text:'No se pudo cambiar la clave, por favor intente mas tarde.',
            icon:'warning'
          });
        }
        else if(VerificaCambio =="2"){
          Swal.fire({
            title: 'Alerta',
            text:'La contraseña actual no coincide con la registrada.',
            icon:'warning'
          });
        }
      });
    }
    else{
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios, por favor verifique.',
        icon: 'warning'
      });
    }
  }
}
