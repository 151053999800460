import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {

  // Se realiza la creacion de variables globales
  Pedidos = [];

  constructor(private serviciosService:ServiciosService) { }

  ngOnInit() {
    
    //Se va a la parte superior de la pantalla
    window.scroll(0,0);

    //Se captura el Id del local storage
    var InfoCliente = [];
    InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    var Id = InfoCliente[0]["Id"];

    //Se realiza la consulta de los pedidos
    this.serviciosService.getListaPedidos(Id)
    .subscribe((data:[])=>{

      // Se asigna la respuesta a la variable
      this.Pedidos = data;
    });

  }

}
