import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd, ParamMap} from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-recordar-clave',
  templateUrl: './recordar-clave.component.html',
  styleUrls: ['./recordar-clave.component.css']
})
export class RecordarClaveComponent implements OnInit {

  constructor(private serviciosService:ServiciosService,private router: Router) { }
  //Se crean los campos globales
  Correo:string="";
  ClaveTemp:string = "";
  ClaveNueva:string = "";
  ClaveNuevaC:string = "";

  ngOnInit() {
  }
  //--------------------------------------------------
  //Funcion para el cambio de contraseña
  //--------------------------------------------------
  RestablecerClave()
  {
    //Se realiza la validacion de que los campos esten llenos
    if(this.Correo == "" || this.ClaveTemp == "" || this.ClaveNueva == "" || this.ClaveNuevaC == "")
    {
      Swal.fire({
        title: 'Alerta',
        text: 'Existen campos vacios, por favor verifique.',
        icon: 'warning'
      });
      return;
    }
    //Se valida conicidencia de la clave nueva y la confirmacion
    if(this.ClaveNueva == this.ClaveNuevaC)
    {
      // Se crea el arreglo con la informacion a restablecer
      var InfoRecordar = [];
      InfoRecordar.push({"Correo":this.Correo,"ClaveTemp":this.ClaveTemp,"ClaveNueva":this.ClaveNueva});
      //Se llama el servicio para retablecer la clave
      this.serviciosService.RestablecerClave(InfoRecordar)
      .subscribe((data:[])=>{
        var RtaCambioClave = [];
        RtaCambioClave = data;
        var VerificaCambio = RtaCambioClave["Error"];
        //Se verifica si se presentaron errores
        if(VerificaCambio =="0")
        {
          this.router.navigate(['home']);
          Swal.fire({
            text: 'Contraseña restablecida con éxito.',
            icon: 'success'
          });
          localStorage.removeItem("Pedido");
          localStorage.removeItem("InfoCliente");

        }else if(VerificaCambio =="1"){
          Swal.fire({
            title: 'Alerta',
            text:'No se pudo cambiar la clave, por favor intente mas tarde.',
            icon:'warning'
          });
        }
        else if(VerificaCambio =="2"){
          Swal.fire({
            title: 'Alerta',
            text:'La contraseña temporal no coincide con la registrada.',
            icon:'warning'
          });
        }
        else if(VerificaCambio =="3"){
          Swal.fire({
            title: 'Alerta',
            text:'El correo ingresado no esta registrado en nuestro sitio web.',
            icon:'warning'
          });
        }
      });
    }

    else{
      Swal.fire({
        title: 'Alerta',
        text: 'La nueva clave no coincide con la confirmación, por favor verifique.',
        icon: 'warning'
      });
      return;
    }
  }
}
