import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  //Se crean las variables globales
  IdLegal:string ="";
  Descripcion:string = "";
  Contenido:string = "";
  InfoLegal = [];

  constructor(private serviciosService:ServiciosService, private activatedRoute:ActivatedRoute,private router:Router) { }

  ngOnInit() {
    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      window.scroll(0,0);
        //Se captura el Id del Catalogo
        this.IdLegal = params.get('Id');

        //Se realiza la consulta de la información del catalogo
        this.serviciosService.getInfoLegal(this.IdLegal)
        .subscribe((data:[])=>{
          this.InfoLegal = data;
    
          // Se estable la descripcion del catalogo
          this.Descripcion = this.InfoLegal[0]['Descripcion'];
          this.Contenido = this.InfoLegal[0]['Contenido'];
        });
    });
  }
}
