import { Component, OnInit } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';


@Component({
  selector: 'app-lista-blogs',
  templateUrl: './lista-blogs.component.html',
  styleUrls: ['./lista-blogs.component.css']
})
export class ListaBlogsComponent implements OnInit {
  //se crean las variables globales
  ListaBlogs = [];

  constructor(private serviciosService:ServiciosService) { }

  //Funcion de cargue inicial de la pagina
  ngOnInit() {
    // Se captura el Id del pedido
    window.scroll(0,0);
    //Se realiza la consulta de los blogs Activos
    this.serviciosService.getListaBlogs()
    .subscribe((data:[])=>{
      this.ListaBlogs = data;
    });
  }
}
