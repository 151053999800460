import { Component, OnInit } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';
import { Router,ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-detalle-blog',
  templateUrl: './detalle-blog.component.html',
  styleUrls: ['./detalle-blog.component.css']
})
export class DetalleBlogComponent implements OnInit {

  //Se crean las variables globales
  InfoBlog = [];
  Titulo:string = "";
  Tema:string = "";
  Parrafo1:string = "";
  Parrafo2:string = "";
  Imagen1:string = "";
  Imagen2:string = "";
  IdBlog:string = "";
  Fecha:string = "";
  Video:SafeUrl = "";
  Cvideo:boolean = false;
  Cimagen1:boolean = false;
  Cimagen2:boolean = false;

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute,private sanitizer: DomSanitizer) { }

  //Funcion de cargue inicial de la pagina
  ngOnInit() {
     // Se captura el Id del pedido
     window.scroll(0,0);
     //Se captura el Id del Catalogo
    this.IdBlog = this.activatedRoute.snapshot.params['Id'];
    //Se realiza la consulta del detalle del blog
    this.serviciosService.getDetalleBlog(this.IdBlog)
    .subscribe((data:[])=>{
      this.InfoBlog = data;
      //Se obtiene la informacion del blog
      this.Titulo = this.InfoBlog[0]['Titulo'];
      this.Tema = this.InfoBlog[0]['Tema'];
      this.Imagen1 = this.InfoBlog[0]['Imagen1'];
      this.Imagen2 = this.InfoBlog[0]['Imagen2'];
      this.Parrafo1 = this.InfoBlog[0]['Parrafo1'];
      this.Parrafo2 = this.InfoBlog[0]['Parrafo2'];
      this.Fecha = this.InfoBlog[0]['Fecha'];
      this.Video = this.sanitizer.bypassSecurityTrustResourceUrl(this.InfoBlog[0]['Video']);
      console.log(this.Video);

      //Se verifica si los contenedores estan vacios
      //Para el video
      if(this.InfoBlog[0]['Video'] != null)
      {
        this.Cvideo = true;
      }
      //para la imagen 1
      if(this.Imagen1 != null)
      {
        this.Cimagen1 = true;
      }
      //Para la imagen 2
      if(this.Imagen2 != null)
      {
        this.Cimagen2 = true;
      }
    });
  }

}
