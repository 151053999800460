import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd, ParamMap} from '@angular/router';

@Component({
  selector: 'app-productos-categoria',
  templateUrl: './productos-categoria.component.html',
  styleUrls: ['./productos-categoria.component.css']
})
export class ProductosCategoriaComponent implements OnInit {
  // Se crean las variables globales
  IdCategoria:string;
  ListaProductos = [];
  ListaMarcas = [];
  ListaSubCategoria = [];
  InfoCategoria = [];
  DescripcionCategoria:string = "";
  ListaFiltros=[];
  DescuentoCliente:number=0;
  Orden:number = 0;
  p:number = 1;

  constructor(private serviciosService:ServiciosService, private activatedRoute:ActivatedRoute, private router:Router) { 
  }

  ngOnInit() {
    // Se va al inicio de la pagina
    window.scroll(0,0)

    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      //Se captura el Id del Catalogo
      this.IdCategoria = params.get('Id');

        // Se realiza la consulta de los productos
        this.serviciosService.getProductosCategoria(this.IdCategoria,this.Orden)
        .subscribe((data:[])=>{
          this.ListaProductos = data;
        });

        // Se realiza la consulta de la informacion de la subcategoria
        this.serviciosService.getInfoCategoria(this.IdCategoria)
        .subscribe((data:[])=>{
          this.InfoCategoria = data;
          this.DescripcionCategoria = this.InfoCategoria[0]['Descripcion'];
        });

        // Se realiza la consulta de las Suncategorias
        this.serviciosService.getSubCategoriasCategorias(this.IdCategoria)
        .subscribe((data:[])=>{
          this.ListaSubCategoria = data;
        });

        // Se realiza la consulta de las marcas
        this.serviciosService.getMarcasProductosCategoria(this.IdCategoria)
        .subscribe((data:[])=>{
          this.ListaMarcas = data;
        });
    });

    // Se captura la informacion del local storage
    var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
    this.DescuentoCliente = InfoCliente[0]['Descuento']/100;
  }
  //----------------------------------------------------------------
  //Funcion para agregar filtros
  //----------------------------------------------------------------
  AgregarFiltro(Id:string,Descripcion:string,Tipo:string){
    // Se verifica si el registro ya existe en el filtro
    var Verifica = this.ListaFiltros.find( Registro => Registro.Nombre == Descripcion );

    //Si el registro no existe se agrega al array
    if(Verifica == undefined)
    {
      // Se agrega el regitro
      this.ListaFiltros.push({Id:Id,Nombre:Descripcion,Tipo:Tipo});
      
      //Se crean las variables
      var SubCategorias = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'SubCategoria')
        {
          if(SubCategorias == "")
          {
            SubCategorias = this.ListaFiltros[Registro].Id;
          }
          else
          {
            SubCategorias = SubCategorias +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      this.p = 1;
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosCategoria(this.IdCategoria,SubCategorias,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  }
  //----------------------------------------------------------------------
  //Funcion para remover filtros
  //----------------------------------------------------------------------
  RemoverFiltro(Id:string)
  {
    //Se remueve el filtro seleccionado
    this.ListaFiltros = this.ListaFiltros.filter(function(ListaFiltros){
      return ListaFiltros.Id !==Id;
    });

    //Se crean las variables del servicio
    var SubCategorias = "";
    var Marcas = "";

    // Se recorren los registros del array
    for(var Registro in this.ListaFiltros)
    {
      //Se capturan los registros
      var TipoFiltro = this.ListaFiltros[Registro].Tipo;

      //Se verifica el tipo para agregarlo a la consulta
      if(TipoFiltro == 'SubCategoria')
      {
        if(SubCategorias == "")
        {
          SubCategorias = this.ListaFiltros[Registro].Id;
        }
        else
        {
          SubCategorias = SubCategorias +','+ this.ListaFiltros[Registro].Id;
        }
      }
      if(TipoFiltro == 'Marca')
      {
        if(Marcas == "")
        {
          Marcas = this.ListaFiltros[Registro].Id;
        }
        else
        {
          Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
        }
      }
    }
    //Se va a la pagina 1
    this.p = 1
    //Se realiza la busqueda con el filtro definido
    this.serviciosService.getFiltroProductosCategoria(this.IdCategoria,SubCategorias,Marcas,this.Orden)
    .subscribe((data:[])=>{
      this.ListaProductos = data;
    });
  }

    //----------------------------------------------------------------------
    //Funcion para borrar todos los filtros
    //----------------------------------------------------------------------
    BorrarFiltros()
    {
      //Se limpian todos los filtros agregados
      this.ListaFiltros = [];

      //Se va a la pagina 1
      this.p = 1
  
      //Se realiza la consulta de los productos
      this.serviciosService.getProductosCategoria(this.IdCategoria,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
  //----------------------------------------------------------------
  //Funcion para ordenar los registros
  //----------------------------------------------------------------
  Ordenamiento()
  {
    //Se verifica si hay filtros
    var NumFiltros = this.ListaFiltros.length;

    //Se va a la pagina 1
    this.p = 1

    //Se verifican los filtros
    if(NumFiltros == 0)
    {
      //Se realiza la consulta de los productos
      this.serviciosService.getProductosCategoria(this.IdCategoria,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });
    }
    else{
      //Se crean las variables del servicio
      var SubCategorias = "";
      var Marcas = "";

      // Se recorren los registros del array
      for(var Registro in this.ListaFiltros)
      {
        //Se capturan los registros
        var TipoFiltro = this.ListaFiltros[Registro].Tipo;

        //Se verifica el tipo para agregarlo a la consulta
        if(TipoFiltro == 'SubCategoria')
        {
          if(SubCategorias == "")
          {
            SubCategorias = this.ListaFiltros[Registro].Id;
          }
          else
          {
            SubCategorias = SubCategorias +','+ this.ListaFiltros[Registro].Id;
          }
        }
        if(TipoFiltro == 'Marca')
        {
          if(Marcas == "")
          {
            Marcas = this.ListaFiltros[Registro].Id;
          }
          else
          {
            Marcas = Marcas +','+ this.ListaFiltros[Registro].Id;
          }
        }
      }
      //Se realiza la busqueda con el filtro definido
      this.serviciosService.getFiltroProductosCategoria(this.IdCategoria,SubCategorias,Marcas,this.Orden)
      .subscribe((data:[])=>{
        this.ListaProductos = data;
      });

    }
  }
}
