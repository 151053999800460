import { Component, OnInit } from '@angular/core';
import {ServiciosService} from '../services/servicios.service';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import Swal from 'sweetalert2';
import * as CryptoJS  from 'crypto-js';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  //Se crea las variables globales
  Usuario:string = "";
  Clave:string = "";
  InfoUsuario = [];

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute, private router:Router) { }

  
  ngOnInit() {
  }

  //---------------------------------------------------------------------------
  // Se realiza el loguin
  //---------------------------------------------------------------------------
  Login()
  {
    //Se verifica que los dos campos este llenos
    if(this.Usuario !="" && this.Clave !="")
    {
      //Se crean las variables para verificar si existe el usuario
      var VerificaUsuario = [];
      var VerificaRespuesta:string = "";
      // Se captura la informacion del local storage
      var InfoCliente = JSON.parse(localStorage.getItem("InfoCliente"));
      var DescuentoCliente = InfoCliente[0]['Descuento'];

      // Se verifica si el usuario esta registrado
      this.serviciosService.getVerificaUsuario(this.Usuario)
      .subscribe((data:[])=>{
        VerificaUsuario = data;
        VerificaRespuesta = VerificaUsuario[0]['Verifica'];
        if(VerificaRespuesta=="0")
        {
          Swal.fire({
            title: 'Alerta',
            text: 'El usuario no se encuentra registrado en nuestro portal.',
            icon: 'warning'
          });
        }
        else{
          var InfoLogin = [];
          InfoLogin.push({"Usuario":this.Usuario,"Clave":this.Clave})
          //Se llama el servicio de login
          this.serviciosService.getLogin(InfoLogin)
          .subscribe((data:[])=>{
            this.InfoUsuario = data;
            //Se verifica si hay registros
            var Verifica:number = this.InfoUsuario.length;

            //Se veririfica la respuesta
            if(Verifica > 0){
              var url= window.location.hash;
              // Se limpia la info del cliente
              var InfoCliente = [{"Id":this.InfoUsuario[0]["IdUsuario"],"CodigoSap":this.InfoUsuario[0]["CodigoSap"],"Descuento":DescuentoCliente,"Nombres":this.InfoUsuario[0]["Nombres"],"Apellidos":this.InfoUsuario[0]["Apellidos"],"Identificacion":this.InfoUsuario[0]["Identificacion"],"Celular":this.InfoUsuario[0]["Celular"],"Login":1,"Correo":this.InfoUsuario[0]["Correo"]}]
              localStorage.removeItem("InfoCliente");
              localStorage.setItem("InfoCliente",JSON.stringify(InfoCliente));
              //Se actualiza el token
              localStorage.removeItem("Token");
              localStorage.setItem("Token",CryptoJS.AES.encrypt(this.InfoUsuario[0]["Token"].trim(),"Marpico2021*").toString());
              this.router.navigate(['home']);

              //Se verifica la ruta para actualizar.
              if(url =="#/home")
              {
                window.location.reload();
              }
            }
            else{
              Swal.fire({
                title: 'Alerta',
                text: 'Credenciales incorrectas por favor verifique.',
                icon: 'warning'
              });
            }
          });
        }
      });
    }
    else{
      Swal.fire({
        title: 'Alerta',
        text: 'Existen Campos Vacios por favor verifique.',
        icon: 'warning'
      });
    }
  }

  //-----------------------------------------------------------------------
  // Funcion para cargar la informacion de las ciudades del departamento
  //-----------------------------------------------------------------------
  RecordarClave()
  {
    //Se crea la variable para capturar la respuesta
    var InfoRecordar = [];
    var VerificaUsuario = [];
    var VerificaRespuesta:string = "";

    //Se verifica que el campo de correo 
    if(this.Usuario == "")
    {
      Swal.fire({
        title: 'Alerta',
        text: 'El campo del usuario se encuentra vacio, por favor verifique.',
        icon: 'warning'
      });
      return;
    }

    // Se verifica si el usuario esta registrado
    this.serviciosService.getVerificaUsuario(this.Usuario)
    .subscribe((data:[])=>{
      VerificaUsuario = data;
      VerificaRespuesta = VerificaUsuario[0]['Verifica'];
      if(VerificaRespuesta=="0")
      {
        Swal.fire({
          title: 'Alerta',
          text: 'El usuario no se encuentra registrado en nuestro portal.',
          icon: 'warning'
        });
      }
      else{
        //Se ejecuta el servicio de recordar clave
        this.serviciosService.getRecordarClave(this.Usuario)
        .subscribe((data:[])=>{
          InfoRecordar = data;
          //Se verifica la respuesta 
          var Verifica = InfoRecordar['Error'];

          //Se verifica la respuesta
          if(Verifica =="0")
          {
            Swal.fire({
              text: 'Se ha enviado la contraseña a su correo, por favor verifique.',
              icon: 'success'
              });
          }
          else{
            Swal.fire({
              title: 'Alerta',
              text: 'Se presento un error en el envio del correo, intente de nuevo.',
              icon: 'warning'
              });
          }
        });
      }
    });
  }
}
